import React from 'react';
import { SelectedItem, SelectedItemContextType } from '../@types/item';

export const ItemContext = React.createContext<SelectedItemContextType | null>(null);

const ItemProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [item, setItem] = React.useState<SelectedItem>(
    {
      id: 'abcde',
      owner: 'undefined',
      visible: false,
      soutionsFitVisible: false,
      businessFitVisible: false, 
      editBusinessFitVisible: false
    }
  );
  const updateItem = (item: SelectedItem) => {
    setItem(item);
  };
  return <ItemContext.Provider value={{ item, updateItem }}>{children}</ItemContext.Provider>;
};

export default ItemProvider;