/* Copyright 2023 Amazon.com and its affiliates; all rights reserved. This file is Amazon Web Services Content and may not be duplicated or distributed without permission. */

import React, { useEffect, useState } from 'react';
import PieChart from "@cloudscape-design/components/pie-chart";
import Box from "@cloudscape-design/components/box";
import Button from "@cloudscape-design/components/button";
import  Container from "@cloudscape-design/components/container";
import Header from "@cloudscape-design/components/header";

export default function Chart(props: any) {

    //fields for a Pie or Donut chart
    const data = props.data;
    const visibleSegments = props.visibleSegments;
    const segmentDescription = props.segmentDescription;
    const chartDescription = props.chartDescription;
    const chartLabel = props.chartLabel;
    const innerMetricDescription = props.innerMetricDescription;
    const innerMetricValue = props.innerMetricValue;
    const size = props.size;
    const variant = props.variant;
    const chartLoading = props.statusType;

    return (
        <Container
      header={
        <Header
          variant="h2"
          description={chartDescription}
        >
          {chartLabel}
        </Header>
      }
    >
       <PieChart
            data={data}
            statusType={(chartLoading ? 'loading' : 'finished' )}
            visibleSegments={visibleSegments}
            segmentDescription={segmentDescription}
            ariaDescription={chartDescription}
            ariaLabel={chartLabel}
            innerMetricDescription={innerMetricDescription}
            innerMetricValue={innerMetricValue}
            size={size}
            variant={variant}
            hideFilter
            hideLegend  
            empty={
                <Box textAlign="center" color="inherit">
                  <b>No data available</b>
                  <Box variant="p" color="inherit">
                    There is no data available
                  </Box>
                </Box>
              }
              noMatch={
                <Box textAlign="center" color="inherit">
                  <b>No matching data</b>
                  <Box variant="p" color="inherit">
                    There is no matching data to display
                  </Box>
                  <Button>Clear filter</Button>
                </Box>
              }
              />
    </Container>

       
    )



}