import { Box, Input, ButtonDropdown, SpaceBetween, ColumnLayout, StatusIndicator, ExpandableSection, Slider } from '@cloudscape-design/components';
import { useEffect, useState } from 'react';

export default function EditItemDetailQualificationChecklist(props: any) {

    const data = props.data;
    const sourceData = props.sourceData;
    const updateParent = props.sendDataToParent;
    const qualiChecklistScore = data.qualiChecklistScore;
    const qualiChecklistType = data.type;

    //Basic Info Fields
    const [oppyDeadline, setOppyDeadline] = useState("");
    const [awsAccountExec, setAwsAccountExec] = useState("");
    const [salesforceOppyURL, setSalesforceOppyURL] = useState("https://aws-crm.lightning.force.com/lightning/r/Opportunity/[Opportunity ID]");
    const [oppyForecastedDate, setOppyForecastedDate] = useState('');
    const [connectSpecialist, setConnectSpecialist] = useState('');

    // Customer Info Fields 
    const [companyName, setCompanyName] = useState("");
    const [companyIndustry, setCompanyIndustry] = useState("");
    const [mainOfficeAddress, setMainOfficeAddress] = useState("");
    const [regionCountry, setRegionCountry] = useState("");
    const [primaryBusinessContactName, setPrimaryBusinessContactName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [emailAddress, setEmailAddress] = useState("");

    // Checklist Input Fields
    const [frameworkTender, setFrameworkTender] = useState("No");
    const [awsCustomer, setAwsCustomer] = useState("No");
    const [influence, setInfluence] = useState("No");
    const [competition, setCompetition] = useState("No");
    const [accountPlan, setAccountPlan] = useState("No");
    const [opportunitySeatSize, setOpportunitySeatSize] = useState("99");
    const [opportunityType, setOpportunityType] = useState("Digital only");
    const [expectedARR, setExpectedARR] = useState(1);
    const [awsSentiment, setAwsSentiment] = useState("No");
    const [buildVsBuy, setBuildVsBuy] = useState("Build");
    const [effort, setEffort] = useState(2);
    const [partnerLed, setPartnerLed] = useState("Yes");
    const [consultingPartner, setConsultinPartner] = useState("No");


    useEffect(() => {
      if (sourceData[0]) {
          setOppyDeadline(sourceData[0].oppyDeadline);
          setAwsAccountExec(sourceData[0].awsAccountExec);
          setCompanyName(sourceData[0].companyName);
          setCompanyIndustry(sourceData[0].companyIndustry);
          setMainOfficeAddress(sourceData[0].mainOfficeAddress);
          setRegionCountry(sourceData[0].regionCountry);
          setPrimaryBusinessContactName(sourceData[0].primaryBusinessContactName);
          setPhoneNumber(sourceData[0].phoneNumber);
          setEmailAddress(sourceData[0].emailAddress);
          setSalesforceOppyURL(sourceData[0].salesforceOppyURL);
          setConnectSpecialist(sourceData[0].connectSpecialist);
          setOppyForecastedDate(sourceData[0].oppyForecastedDate);
          setFrameworkTender(sourceData[0].frameworkTender);
          setAwsCustomer(sourceData[0].awsCustomer);
          setInfluence(sourceData[0].influence);
          setCompetition(sourceData[0].competition);
          setAccountPlan(sourceData[0].accountPlan);
          setOpportunitySeatSize(sourceData[0].opportunitySeatSize);
          setOpportunityType(sourceData[0].opportunityType);
          setExpectedARR(sourceData[0].expectedARR);
          setAwsSentiment(sourceData[0].awsSentiment);
          setBuildVsBuy(sourceData[0].buildVsBuy);
          setEffort(sourceData[0].effort);
          setPartnerLed(sourceData[0].partnerLed);
          setConsultinPartner(sourceData[0].consultingPartner);


      }

  }, [data, sourceData])

    useEffect(() => {
      console.log(`Updating parent: ${opportunitySeatSize}`);
      const object = {
        frameworkTender: frameworkTender,
        awsCustomer: awsCustomer,
        influence: influence,
        competition: competition,
        accountPlan: accountPlan,
        opportunitySeatSize: opportunitySeatSize,
        opportunityType: opportunityType,
        expectedARR: expectedARR,
        awsSentiment: awsSentiment,
        buildVsBuy: buildVsBuy,
        effort: effort,
        partnerLed: partnerLed,
        consultingPartner: consultingPartner
    };
    console.log(`Updating Parent with: ${JSON.stringify(object)}`);
    updateParent(object);
    }, [  frameworkTender, awsCustomer, influence, competition, accountPlan, opportunitySeatSize, opportunityType, expectedARR, awsSentiment, buildVsBuy, effort, partnerLed, consultingPartner]);

    

    const content =
        (
              <ExpandableSection headerText='Opportunity Information'>
                      <ColumnLayout columns={4} variant="text-grid">
                        <SpaceBetween size="l">
                          <div>
                            <Box variant="awsui-key-label">Framework tender</Box>
                            <ButtonDropdown
                          items={[
                            { text: "Yes", id: "Yes", disabled: false },
                            { text: "No", id: "No", disabled: false }
                          ]}
                          onItemClick={(event) => {
                            console.log(`The user clicked on the ${JSON.stringify(event.detail.id)} menu item`);
                            setFrameworkTender(event.detail.id);
                          }}
                        >{frameworkTender} </ButtonDropdown>
                            </div>
                          <div>
                            <Box variant="awsui-key-label">AWS Customer</Box>
                            <ButtonDropdown
                          items={[
                            { text: "Yes", id: "Yes", disabled: false },
                            { text: "No", id: "No", disabled: false }
                          ]}
                          onItemClick={(event) => {
                            console.log(`The user clicked on the ${JSON.stringify(event.detail.id)} menu item`);
                            setAwsCustomer(event.detail.id);
                          }}
                        >{awsCustomer} </ButtonDropdown>
                            </div>
                          <div>
                            <Box variant="awsui-key-label">Influence</Box>
                            <ButtonDropdown
                          items={[
                            { text: "Yes", id: "Yes", disabled: false },
                            { text: "No", id: "No", disabled: false }
                          ]}
                          onItemClick={(event) => {
                            console.log(`The user clicked on the ${JSON.stringify(event.detail.id)} menu item`);
                            setInfluence(event.detail.id);
                          }}
                        >{influence} </ButtonDropdown>
                            </div>
                          <div>
                            <Box variant="awsui-key-label">Competition</Box>
                            <ButtonDropdown
                          items={[
                            { text: "Yes", id: "Yes", disabled: false },
                            { text: "No", id: "No", disabled: false }
                          ]}
                          onItemClick={(event) => {
                            console.log(`The user clicked on the ${JSON.stringify(event.detail.id)} menu item`);
                            setCompetition(event.detail.id);
                          }}
                        >{competition} </ButtonDropdown>
                            </div>
                        </SpaceBetween>

                        <SpaceBetween size="l">
                          <div>
                            <Box variant="awsui-key-label">Account Plan</Box>
                            <ButtonDropdown
                          items={[
                            { text: "Yes", id: "Yes", disabled: false },
                            { text: "No", id: "No", disabled: false }
                          ]}
                          onItemClick={(event) => {
                            console.log(`The user clicked on the ${JSON.stringify(event.detail.id)} menu item`);
                            setAccountPlan(event.detail.id);
                          }}
                        >{accountPlan} </ButtonDropdown>
                            </div>
                          <div>
                            <Box variant="awsui-key-label">Seat size</Box>
                            <Input value={opportunitySeatSize} onChange={({ detail }) => {
                          setOpportunitySeatSize(detail.value)
                        }} inputMode="numeric" /></div>
                          <div>
                            <Box variant="awsui-key-label">Opportunity Type</Box>
                            <ButtonDropdown
                          items={[
                            { text: "Digital Only", id: "Digital Only", disabled: false },
                            { text: "Voice Only", id: "Voice Only", disabled: false },
                            { text: "Voice + Digital", id: "Voice + Digital", disabled: false }
                          ]}
                          onItemClick={(event) => {
                            console.log(`The user clicked on the ${JSON.stringify(event.detail.id)} menu item`);
                            setOpportunityType(event.detail.id);
                          }}
                        >{opportunityType} </ButtonDropdown>
                            </div>
                          <div>
                            <Box variant="awsui-key-label">Expected ARR</Box>
                            <Input value={String(expectedARR)} onChange={({ detail }) => {
                          setExpectedARR(Number(detail.value.replace(',', '.')))
                        }} inputMode="decimal" type='number' step={0.1} />
                          </div>
                        </SpaceBetween>

                        <SpaceBetween size="l">
                          <div>
                            <Box variant="awsui-key-label">AWS Sentiment</Box>
                            <ButtonDropdown
                          items={[
                            { text: "Yes", id: "Yes", disabled: false },
                            { text: "No", id: "No", disabled: false }
                          ]}
                          onItemClick={(event) => {
                            console.log(`The user clicked on the ${JSON.stringify(event.detail.id)} menu item`);
                            setAwsSentiment(event.detail.id);
                          }}
                        >{awsSentiment} </ButtonDropdown>
                          </div>
                          <div>
                            <Box variant="awsui-key-label">Builder or Buyer</Box>
                            <ButtonDropdown
                          items={[
                            { text: "Build", id: "Build", disabled: false },
                            { text: "Buy", id: "Buy", disabled: false }
                          ]}
                          onItemClick={(event) => {
                            console.log(`The user clicked on the ${JSON.stringify(event.detail.id)} menu item`);
                            setBuildVsBuy(event.detail.id);
                          }}
                        >{buildVsBuy} </ButtonDropdown>
                          </div>
                          <div>
                            <Box variant="awsui-key-label">Partner led</Box>
                            <ButtonDropdown
                          items={[
                            { text: "Yes", id: "Yes", disabled: false },
                            { text: "No", id: "No", disabled: false }
                          ]}
                          onItemClick={(event) => {
                            console.log(`The user clicked on the ${JSON.stringify(event.detail.id)} menu item`);
                            setPartnerLed(event.detail.id);
                          }}
                        >{partnerLed} </ButtonDropdown>
                          </div>
                          <div>
                            <Box variant="awsui-key-label">Consulting Partner</Box>
                            <ButtonDropdown
                          items={[
                            { text: "Yes", id: "Yes", disabled: false },
                            { text: "No", id: "No", disabled: false }
                          ]}
                          onItemClick={(event) => {
                            console.log(`The user clicked on the ${JSON.stringify(event.detail.id)} menu item`);
                            setConsultinPartner(event.detail.id);
                          }}
                        >{consultingPartner} </ButtonDropdown>
                          </div>
                        </SpaceBetween>

                        <SpaceBetween size="l">
                          <Box variant="awsui-key-label">Estimated Effort</Box>
                          <Slider
                          onChange={({ detail }) => { setEffort(detail.value) }}
                          value={effort}
                          valueFormatter={value =>
                            [
                              { value: "0", label: "None" },
                              { value: "1", label: "Low" },
                              { value: "2", label: "Medium" },
                              { value: "3", label: "High" }
                            ].find(item => item.value === value.toString())
                              ?.label || ""
                          }
                          ariaDescription="From None to High"
                          max={3}
                          min={0}
                          referenceValues={[1, 2]}
                          step={1}
                          tickMarks

                        />
                        </SpaceBetween>
                      </ColumnLayout>
                      </ExpandableSection>
        )
    return content
}
