'use client';
import * as React from 'react';
import { StatusIndicator, Icon } from '@cloudscape-design/components';

export type Item = {
  id: string;
  companyName: string;
  accountPlan?: string;
  regionCountry?: string;
  companyIndustry?: string;
  oppyDeadline?: string;
  emailAddress?: string;
  influence?: string;
  owner: string;
  opportunitySeatSize?: string;
  effort?: string;
  phoneNumber?: string;
  partnerLed?: string;
  opportunityType?: string;
  consultingPartner?: string;
  oppyForecastedDate?: string;
  buildVsBuy?: string;
  connectSpecialist?: string;
  awsCustomer?: string;
  salesforceOppyURL?: string;
  expectedARR?: string;
  awsSentiment?: string;
  connectSpecialistSA?: string;
  mainOfficeAddress?: string;
  competition?: string;
  frameworkTender?: string;
  awsAccountExec?: string;
  primaryBusinessContactName?: string;
  createdAt: Date;
  updatedAt: Date;
  preQualiChecklist?: string;
  postQualificationBusiness?: string;
  postQualificationSolution?: string;
  winLossAnalysis?: string;
  requestType?: string;
  tenderOutcome?: string;
  qualificationState?: {
    value: string;
    label: string;
    icon?: string;
  }
};

export type Preferences = {
    pageSize?: number | undefined;
    contentDisplay?: {
        id: string;
        visible: boolean;
    }[] | undefined;
}


export function getMatchesCountText(count: number): string {
  return count === 1 ? `1 match` : `${count} matches`;
}

function formatDate(date: Date): string {
  const dateFormatter = new Intl.DateTimeFormat('en-US', { dateStyle: 'long' });
  const timeFormatter = new Intl.DateTimeFormat('en-US', { timeStyle: 'short', hour12: false });
  return `${dateFormatter.format(date)}, ${timeFormatter.format(date)}`;
}

function formatStatusIndicator(status: string): React.ReactNode {
  if(status === 'Completed') {
    return (<StatusIndicator type='success'>{status}</StatusIndicator>)
  } else {
    return (<StatusIndicator type='warning'>{status}</StatusIndicator>)
  }
}

function formatWinLossIndicator(status: string): React.ReactNode{
  if(status === 'Win') {
    return (<><Icon name='star-filled' variant='success' /> {status}</>)
  }
  if (status === 'Loss') {
    return (<><Icon name='status-negative' variant='error' /> {status}</>)
  }
  return(<><Icon name='calendar' variant='normal'/> Pending </>)
}

function createLabelFunction(columnName: string) {
  return ({ sorted, descending }: { sorted: boolean; descending: boolean }): string => {
    const sortState = sorted ? `sorted ${descending ? 'descending' : 'ascending'}` : 'not sorted';
    return `${columnName}, ${sortState}.`;
  };
}

export type ColumnDefinition = {
  id: string;
  header: string;
  cell: (item: Item) => React.ReactNode;
  ariaLabel: (props: { sorted: boolean; descending: boolean }) => string;
  sortingField?: string;
  sortingComparator?: (a: Item, b: Item) => number;
  isRowHeader?: boolean;
};

export type DetailDefintion = {
  valueName: string;
  objectType: string;
  header: string;
}

export const detailDefinitions: DetailDefintion[] = [
  { valueName: 'accountPlan', objectType: 'string', header: 'Account Plan' },
  { valueName: 'regionCountry', objectType: 'string', header: 'Region/Country' },
  { valueName: 'companyIndustry', objectType: 'string', header: 'Company Industry' },
  { valueName: 'oppyDeadline', objectType: 'string', header: 'Opportunity Deadline' },
  { valueName: 'emailAddress', objectType: 'string', header: 'Email Address' },
  { valueName: 'influence', objectType: 'string', header: 'Influence' },
  { valueName: 'owner', objectType: 'string', header: 'Owner' },
  { valueName: 'opportunitySeatSize', objectType: 'string', header: 'Opportunity Seat Size' },
  { valueName: 'effort', objectType: 'string', header: 'Effort' },
  { valueName: 'phoneNumber', objectType: 'string', header: 'Phone Number' },
  { valueName: 'partnerLed', objectType: 'string', header: 'Partner Led' },
  { valueName: 'opportunityType', objectType: 'string', header: 'Opportunity Type' },
  { valueName: 'consultingPartner', objectType: 'string', header: 'Consulting Partner' },
  { valueName: 'oppyForecastedDate', objectType: 'string', header: 'Opportunity Forecasted Date' },
  { valueName: 'buildVsBuy', objectType: 'string', header: 'Build Vs Buy' },
  { valueName: 'connectSpecialist', objectType: 'string', header: 'Connect Specialist' },
  { valueName: 'awsCustomer', objectType: 'string', header: 'AWS Customer' },
  { valueName: 'salesforceOppyURL', objectType: 'string', header: 'Salesforce Oppy URL' },
  { valueName: 'expectedARR', objectType: 'string', header: 'Expected ARR' },
  { valueName: 'awsSentiment', objectType: 'string', header: 'AWS Sentiment' },
  { valueName: 'connectSpecialistSA', objectType: 'string', header: 'Connect Specialist SA' },
  { valueName: 'mainOfficeAddress', objectType: 'string', header: 'Main Office Address' },
  { valueName: 'postQualificationSolution', objectType: 'string', header: 'Solutions Checklist'},
  { valueName: 'postQualificationBusiness', objectType: 'string', header: 'Business Checklist'},
  { valueName: 'qualificationState', objectType: 'string', header: 'Qualification State'},

  
]

export const columnDefinitions: ColumnDefinition[] = [
  {
    id: 'id',
    header: 'ID',
    cell: item => item.id,
    ariaLabel: createLabelFunction('id'),
    sortingField: 'id',
  },
  {
    id: 'qualificationState',
    header: 'Qualification State',
    cell: item => item.qualificationState?.value,
    ariaLabel: createLabelFunction('qualificationState'),
    sortingField: 'qualificationState'
  },
  {
    id: 'postQualificationSolution',
    header: 'Solutions Checklist',
    cell: item => formatStatusIndicator(item.postQualificationSolution!),
    ariaLabel: createLabelFunction('postQualificationSolution'),
    sortingField: 'postQualificationSolution'
  },
  {
    id: 'tenderOutcome',
    header: 'Tender Outcome',
    cell: item => formatWinLossIndicator(item.tenderOutcome!),
    ariaLabel: createLabelFunction('tenderOutcome'),
    sortingField: 'tenderOutcome'
  },
  {
    id: 'postQualificationBusiness',
    header: 'Business Checklist',
    cell: item => formatStatusIndicator(item.postQualificationBusiness!),
    ariaLabel: createLabelFunction('postQualificationBusiness'),
    sortingField: 'postQualificationBusiness'
  },
  {
    id: 'accountPlan',
    header: 'Account Plan',
    cell: item => item.accountPlan,
    ariaLabel: createLabelFunction('accountPlan'),
    sortingField: 'accountPlan',
  },
  {
    id: 'regionCountry',
    header: 'Region/Country',
    cell: item => item.regionCountry,
    ariaLabel: createLabelFunction('regionCountry'),
    sortingField: 'regionCountry',
  },
  {
    id: 'companyIndustry',
    header: 'Company Industry',
    cell: item => item.companyIndustry,
    ariaLabel: createLabelFunction('companyIndustry'),
    sortingField: 'companyIndustry',
  },
  {
    id: 'oppyDeadline',
    header: 'Opportunity Deadline',
    cell: item => item.oppyDeadline,
    ariaLabel: createLabelFunction('oppyDeadline'),
    sortingField: 'oppyDeadline',
  },
  {
    id: 'emailAddress',
    header: 'Email Address',
    cell: item => item.emailAddress,
    ariaLabel: createLabelFunction('emailAddress'),
    sortingField: 'emailAddress',
  },
  {
    id: 'influence',
    header: 'Influence',
    cell: item => item.influence,
    ariaLabel: createLabelFunction('influence'),
    sortingField: 'influence',
  },
  {
    id: 'owner',
    header: 'Owner',
    cell: item => item.owner,
    ariaLabel: createLabelFunction('owner'),
    sortingField: 'owner',
  },
  {
    id: 'opportunitySeatSize',
    header: 'Opportunity Seat Size',
    cell: item => item.opportunitySeatSize,
    ariaLabel: createLabelFunction('opportunitySeatSize'),
    sortingField: 'opportunitySeatSize',
  },
  {
    id: 'effort',
    header: 'Effort',
    cell: item => item.effort,
    ariaLabel: createLabelFunction('effort'),
    sortingField: 'effort',
  },
  {
    id: 'phoneNumber',
    header: 'Phone Number',
    cell: item => item.phoneNumber,
    ariaLabel: createLabelFunction('phoneNumber'),
    sortingField: 'phoneNumber',
  },
  {
    id: 'partnerLed',
    header: 'Partner Led',
    cell: item => item.partnerLed,
    ariaLabel: createLabelFunction('partnerLed'),
    sortingField: 'partnerLed',
  },
  {
    id: 'opportunityType',
    header: 'Opportunity Type',
    cell: item => item.opportunityType,
    ariaLabel: createLabelFunction('opportunityType'),
    sortingField: 'opportunityType',
  },
  {
    id: 'consultingPartner',
    header: 'Consulting Partner',
    cell: item => item.consultingPartner,
    ariaLabel: createLabelFunction('consultingPartner'),
    sortingField: 'consultingPartner',
  },
  {
    id: 'oppyForecastedDate',
    header: 'Opportunity Forecasted Date',
    cell: item => item.oppyForecastedDate,
    ariaLabel: createLabelFunction('oppyForecastedDate'),
    sortingField: 'oppyForecastedDate',
  },
  {
    id: 'companyName',
    header: 'Company Name',
    cell: item => item.companyName,
    ariaLabel: createLabelFunction('companyName'),
    sortingField: 'companyName',
  },
  {
    id: 'buildVsBuy',
    header: 'Build vs Buy',
    cell: item => item.buildVsBuy,
    ariaLabel: createLabelFunction('buildVsBuy'),
    sortingField: 'buildVsBuy',
  },
  {
    id: 'connectSpecialist',
    header: 'Connect Specialist',
    cell: item => item.connectSpecialist,
    ariaLabel: createLabelFunction('connectSpecialist'),
    sortingField: 'connectSpecialist',
  },
  {
    id: 'awsCustomer',
    header: 'AWS Customer',
    cell: item => item.awsCustomer,
    ariaLabel: createLabelFunction('awsCustomer'),
    sortingField: 'awsCustomer',
  },
  {
    id: 'salesforceOppyURL',
    header: 'Salesforce Opportunity URL',
    cell: item => item.salesforceOppyURL,
    ariaLabel: createLabelFunction('salesforceOppyURL'),
    sortingField: 'salesforceOppyURL',
  },
  {
    id: 'expectedARR',
    header: 'Expected ARR',
    cell: item => item.expectedARR,
    ariaLabel: createLabelFunction('expectedARR'),
    sortingField: 'expectedARR',
  },
  {
    id: 'awsSentiment',
    header: 'AWS Sentiment',
    cell: item => item.awsSentiment,
    ariaLabel: createLabelFunction('awsSentiment'),
    sortingField: 'awsSentiment',
  },
  {
    id: 'connectSpecialistSA',
    header: 'Connect Specialist SA',
    cell: item => item.connectSpecialistSA,
    ariaLabel: createLabelFunction('connectSpecialistSA'),
    sortingField: 'connectSpecialistSA',
  },
  {
    id: 'mainOfficeAddress',
    header: 'Main Office Address',
    cell: item => item.mainOfficeAddress,
    ariaLabel: createLabelFunction('mainOfficeAddress'),
    sortingField: 'mainOfficeAddress',
  },
  {
    id: 'competition',
    header: 'Competition',
    cell: item => item.competition,
    ariaLabel: createLabelFunction('competition'),
    sortingField: 'competition',
  },
  {
    id: 'frameworkTender',
    header: 'Framework Tender',
    cell: item => item.frameworkTender,
    ariaLabel: createLabelFunction('frameworkTender'),
    sortingField: 'frameworkTender',
  },
  {
    id: 'awsAccountExec',
    header: 'AWS Account Exec',
    cell: item => item.awsAccountExec,
    ariaLabel: createLabelFunction('awsAccountExec'),
    sortingField: 'awsAccountExec',
  },
  {
    id: 'primaryBusinessContactName',
    header: 'Primary Business Contact Name',
    cell: item => item.primaryBusinessContactName,
    ariaLabel: createLabelFunction('primaryBusinessContactName'),
    sortingField: 'primaryBusinessContactName',
  },
  {
    id: 'createdAt',
    header: 'Created At',
    cell: item => formatDate(item.createdAt),
    ariaLabel: createLabelFunction('createdAt'),
    sortingField: 'createdAt'
  },
  {
    id: 'updatedAt',
    header: 'Updated At',
    cell: item => formatDate(item.updatedAt),
    ariaLabel: createLabelFunction('updatedAt'),
    sortingField: 'updatedAt'

  },
  {
    id: 'preQualiChecklist',
    header: 'Pre-Qualification Checklist',
    cell: item => item.preQualiChecklist,
    ariaLabel: createLabelFunction('preQualiChecklist'),
    sortingField: 'preQualiChecklist'
  },
  {
    id: 'winLossAnalysis',
    header: 'Win-Loss Analysis',
    cell: item => item.winLossAnalysis,
    ariaLabel: createLabelFunction('winLossAnalysis'),
    sortingField: 'winLossAnalysis'
  },
  {
    id: 'requestType',
    header: 'Request Type',
    cell: item => item.requestType,
    ariaLabel: createLabelFunction('requestType'),
    sortingField: 'requestType'
  }
  /* TODO: Reserved saving for later data fields
      id: 'companyName',
    header: 'Customer Name',
    cell: item => formatDate(item.lastModified),
    ariaLabel: createLabelFunction('Last modified'),
    sortingComparator: (a, b) => a.lastModified.valueOf() - b.lastModified.valueOf(),
  }, */
];

export const paginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page',
};

type PageSizeOption = {
  value: number;
  label: string;
};

const pageSizePreference: {
  title: string;
  options: PageSizeOption[];
} = {
  title: 'Select page size',
  options: [
    { value: 10, label: '10 resources' },
    { value: 20, label: '20 resources' },
  ],
};

type ContentDisplayOption = {
  id: string;
  label: string;
  alwaysVisible: boolean;
};

const contentDisplayPreference: {
  title: string;
  description: string;
  options: ContentDisplayOption[];
} = {
  title: 'Column preferences',
  description: 'Customize the columns visibility and order.',
  options: columnDefinitions.map(({ id, header }) => ({ id, label: header, alwaysVisible: id === 'companyName' })),
};

export const collectionPreferencesProps = {
  pageSizePreference,
  contentDisplayPreference,
  cancelLabel: 'Cancel',
  confirmLabel: 'Confirm',
  title: 'Preferences',
};