import { Container, Header, ButtonDropdown, ExpandableSection, Box, SpaceBetween, ColumnLayout, StatusIndicator, SplitPanel, Slider, FormField } from '@cloudscape-design/components';
import { useEffect, useState } from 'react';

export default function EditItemDetailBusinesshecklist(props: any) {

  const data = props.data;
  const sourceData = props.sourceData;
  const updateParent = props.sendDataToParent;
  //Business Fit Items

  //Budget
  const [pricingApproach, setPricingAproach] = useState('No');
  const [increaseSize, setIncreaseSize] = useState('No');
  const [owp, setOwp] = useState('No');
  const [currentSpent, setCurrentSpent] = useState('No');

  //Authority
  const [buyerPersoa, setBuyerPersona] = useState('No');
  const [procurementAccess, setProcurementAccess] = useState('No');
  const [decisionProcess, setDecisionProcess] = useState('No');


  //Need
  const [objective, setObjective] = useState('Tactical');
  const [specificOutcome, setSpecificOutcome] = useState('No');
  const [obtainAWSCapability, setObtainAWSCapability] = useState('No');
  const [partnerCost, setPartnerCost] = useState('No');

  //Timing
  const [timescales, setTimescales] = useState('No');
  const [compellingEvent, setCompellingEvent] = useState('No');
  const [capabilityDeadline, setCapabilityDeadline] = useState('No');


  useEffect(() => {
        const object = {
            pricingApproach: pricingApproach,
            increaseSize: increaseSize,
            owp: owp,
            currentSpent: currentSpent,
            buyerPersoa: buyerPersoa,
            procurementAccess: procurementAccess,
            decisionProcess: decisionProcess,
            objective: objective,
            specificOutcome: specificOutcome,
            obtainAWSCapability: obtainAWSCapability,
            partnerCost: partnerCost,
            timescales: timescales,
            compellingEvent: compellingEvent,
            capabilityDeadline: capabilityDeadline,
        }
        console.log(`Updating Parent with: ${JSON.stringify(object)}`);
      updateParent(object);
    
  }, [pricingApproach, increaseSize, owp, currentSpent, buyerPersoa, procurementAccess, decisionProcess, objective, specificOutcome, obtainAWSCapability, partnerCost, timescales, compellingEvent, capabilityDeadline])
  
  useEffect(() => {
    if (sourceData[0]) {
      setPricingAproach(sourceData[0].pricingApproach);
      setIncreaseSize(sourceData[0].increaseSize);
      setOwp(sourceData[0].owp);
      setCurrentSpent(sourceData[0].currentSpent);
      setBuyerPersona(sourceData[0].buyerPersoa);
      setProcurementAccess(sourceData[0].procurementAccess);
      setDecisionProcess(sourceData[0].decisionProcess);
      setObjective(sourceData[0].objective);
      setSpecificOutcome(sourceData[0].specificOutcome);
      setObtainAWSCapability(sourceData[0].obtainAWSCapability);
      setPartnerCost(sourceData[0].partnerCost);
      setTimescales(sourceData[0].timescales);
      setCompellingEvent(sourceData[0].compellingEvent);
      setCapabilityDeadline(sourceData[0].capabilityDeadline);
    }

  }, [data, sourceData])

  const content =
    (
        <ExpandableSection headerText='Business Checklist'>
       <ColumnLayout columns={4} variant="text-grid">
        <SpaceBetween size='l'>
          <div>
            <Box variant='awsui-key-label'>Pricing aproach</Box>
            <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setPricingAproach(event.detail.id);
                                            }}
                                        >{pricingApproach} </ButtonDropdown>
            
          </div>
          <div>
            <Box variant='awsui-key-label'>Increase size</Box>
            <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setIncreaseSize(event.detail.id);
                                            }}
                                        >{increaseSize} </ButtonDropdown>
          </div>
          <div>
            <Box variant='awsui-key-label'>OWP</Box>
            <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setOwp(event.detail.id);
                                            }}
                                        >{owp} </ButtonDropdown>
          </div>
          <div>
            <Box variant='awsui-key-label'>Current spent</Box>
            <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setCurrentSpent(event.detail.id);
                                            }}
                                        >{currentSpent} </ButtonDropdown>
          </div>
        </SpaceBetween>
        <SpaceBetween size='l'>
          <div>
            <Box variant='awsui-key-label'>Buyer persona</Box>
            <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setBuyerPersona(event.detail.id);
                                            }}
                                        >{buyerPersoa} </ButtonDropdown>
          </div>
          <div>
            <Box variant='awsui-key-label'>Procurement access</Box>
            <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setProcurementAccess(event.detail.id);
                                            }}
                                        >{procurementAccess} </ButtonDropdown>
          </div>
          <div>
            <Box variant='awsui-key-label'>Decision process</Box>
            <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setDecisionProcess(event.detail.id);
                                            }}
                                        >{decisionProcess} </ButtonDropdown>
          </div>
          <div>
            <Box variant='awsui-key-label'>Objective</Box>
            <ButtonDropdown
                                            items={[
                                                { text: "Tactical", id: "Tactical", disabled: false },
                                                { text: "Strategic", id: "Strategic", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setObjective(event.detail.id);
                                            }}
                                        >{objective} </ButtonDropdown>
          </div>
        </SpaceBetween>
        <SpaceBetween size='l'>
          <div>
            <Box variant='awsui-key-label'>Specific outcome</Box>
            <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setSpecificOutcome(event.detail.id);
                                            }}
                                        >{specificOutcome} </ButtonDropdown>
          </div>
          <div>
            <Box variant='awsui-key-label'>Obtain AWS capability</Box>
            <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setObtainAWSCapability(event.detail.id);
                                            }}
                                        >{obtainAWSCapability} </ButtonDropdown>
          </div>
          <div>
            <Box variant='awsui-key-label'>Partner cost</Box>
            <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setPartnerCost(event.detail.id);
                                            }}
                                        >{partnerCost} </ButtonDropdown>
          </div>
          <div>
            <Box variant='awsui-key-label'>Timescales</Box>
            <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setTimescales(event.detail.id);
                                            }}
                                        >{timescales} </ButtonDropdown>
          </div>
        </SpaceBetween>
        <SpaceBetween size='l'>
          <div>
            <Box variant='awsui-key-label'>Compelling event</Box>
            <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setCompellingEvent(event.detail.id);
                                            }}
                                        >{compellingEvent} </ButtonDropdown>
          </div>
          <div>
            <Box variant='awsui-key-label'>Capability deadline</Box>
            <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setCapabilityDeadline(event.detail.id);
                                            }}
                                        >{capabilityDeadline} </ButtonDropdown>
          </div>
        </SpaceBetween>
       </ColumnLayout>
       </ExpandableSection>
    )
  return content
}
