import { Container, Header, ExpandableSection, Box, SpaceBetween, ColumnLayout, StatusIndicator, SplitPanel, Slider, FormField } from '@cloudscape-design/components';
import { useEffect, useState } from 'react';

export default function ItemDetailBusinesshecklist(props: any) {

  const data = props.data;
  const sourceData = props.sourceData;
  
  //Business Fit Items

  //Budget
  const [pricingApproach, setPricingAproach] = useState('No');
  const [increaseSize, setIncreaseSize] = useState('No');
  const [owp, setOwp] = useState('No');
  const [currentSpent, setCurrentSpent] = useState('No');

  //Authority
  const [buyerPersoa, setBuyerPersona] = useState('No');
  const [procurementAccess, setProcurementAccess] = useState('No');
  const [decisionProcess, setDecisionProcess] = useState('No');


  //Need
  const [objective, setObjective] = useState('Tactical');
  const [specificOutcome, setSpecificOutcome] = useState('No');
  const [obtainAWSCapability, setObtainAWSCapability] = useState('No');
  const [partnerCost, setPartnerCost] = useState('No');

  //Timing
  const [timescales, setTimescales] = useState('No');
  const [compellingEvent, setCompellingEvent] = useState('No');
  const [capabilityDeadline, setCapabilityDeadline] = useState('No');


  useEffect(() => {
    if (sourceData[0]) {
      setPricingAproach(sourceData[0].pricingApproach);
      setIncreaseSize(sourceData[0].increaseSize);
      setOwp(sourceData[0].owp);
      setCurrentSpent(sourceData[0].currentSpent);
      setBuyerPersona(sourceData[0].buyerPersoa);
      setProcurementAccess(sourceData[0].procurementAccess);
      setDecisionProcess(sourceData[0].decisionProcess);
      setObjective(sourceData[0].objective);
      setSpecificOutcome(sourceData[0].specificOutcome);
      setObtainAWSCapability(sourceData[0].obtainAWSCapability);
      setPartnerCost(sourceData[0].partnerCost);
      setTimescales(sourceData[0].timescales);
      setCompellingEvent(sourceData[0].compellingEvent);
      setCapabilityDeadline(sourceData[0].capabilityDeadline);
    }

  })

  const content =
    (
        <ExpandableSection headerText='Business Checklist'>
       <ColumnLayout columns={4} variant="text-grid">
        <SpaceBetween size='l'>
          <div>
            <Box variant='awsui-key-label'>Pricing aproach</Box>
            <StatusIndicator type={pricingApproach == 'Yes' ? 'success' : 'warning'}>{pricingApproach}</StatusIndicator>
          </div>
          <div>
            <Box variant='awsui-key-label'>Increase size</Box>
            <StatusIndicator type={increaseSize == 'Yes' ? 'success' : 'warning'}>{increaseSize}</StatusIndicator>
          </div>
          <div>
            <Box variant='awsui-key-label'>OWP</Box>
            <StatusIndicator type={owp == 'Yes' ? 'success' : 'warning'}>{owp}</StatusIndicator>
          </div>
          <div>
            <Box variant='awsui-key-label'>Current spent</Box>
            <StatusIndicator type={currentSpent == 'Yes' ? 'success' : 'warning'}>{currentSpent}</StatusIndicator>
          </div>
        </SpaceBetween>
        <SpaceBetween size='l'>
          <div>
            <Box variant='awsui-key-label'>Buyer persona</Box>
            <StatusIndicator type={buyerPersoa == 'Yes' ? 'success' : 'warning'}>{buyerPersoa}</StatusIndicator>
          </div>
          <div>
            <Box variant='awsui-key-label'>Procurement access</Box>
            <StatusIndicator type={procurementAccess == 'Yes' ? 'success' : 'warning'}>{procurementAccess}</StatusIndicator>
          </div>
          <div>
            <Box variant='awsui-key-label'>Decision process</Box>
            <StatusIndicator type={decisionProcess == 'Yes' ? 'success' : 'warning'}>{decisionProcess}</StatusIndicator>
          </div>
          <div>
            <Box variant='awsui-key-label'>Objective</Box>
            <StatusIndicator type={objective == 'Strategic' ? 'success' : 'warning'}>{objective}</StatusIndicator>
          </div>
        </SpaceBetween>
        <SpaceBetween size='l'>
          <div>
            <Box variant='awsui-key-label'>Specific outcome</Box>
            <StatusIndicator type={specificOutcome == 'Yes' ? 'success' : 'warning'}>{specificOutcome}</StatusIndicator>
          </div>
          <div>
            <Box variant='awsui-key-label'>Obtain AWS capability</Box>
            <StatusIndicator type={obtainAWSCapability == 'Yes' ? 'success' : 'warning'}>{obtainAWSCapability}</StatusIndicator>
          </div>
          <div>
            <Box variant='awsui-key-label'>Partner cost</Box>
            <StatusIndicator type={partnerCost == 'Yes' ? 'success' : 'warning'}>{partnerCost}</StatusIndicator>
          </div>
          <div>
            <Box variant='awsui-key-label'>Timescales</Box>
            <StatusIndicator type={timescales == 'Yes' ? 'success' : 'warning'}>{timescales}</StatusIndicator>
          </div>
        </SpaceBetween>
        <SpaceBetween size='l'>
          <div>
            <Box variant='awsui-key-label'>Compelling event</Box>
            <StatusIndicator type={compellingEvent == 'Yes' ? 'success' : 'warning'}>{compellingEvent}</StatusIndicator>
          </div>
          <div>
            <Box variant='awsui-key-label'>Capability deadline</Box>
            <StatusIndicator type={capabilityDeadline == 'Yes' ? 'success' : 'warning'}>{capabilityDeadline}</StatusIndicator>
          </div>
        </SpaceBetween>
       </ColumnLayout>
       </ExpandableSection>
    )
  return content
}
