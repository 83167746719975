import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Root from "./routes/root";
import Home from './routes/Home';
import NewRFX from "./routes/NewRFX";
import MyItems from './routes/MyItems';
import AllItems from './routes/AllItems';
import ErrorPage from "./error-page";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import UserProvider from './context/userContext';
import SelectedItemProvider from './context/itemContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      { path: "Home", element: <Home /> },
      { path: "NewRFX", element: <NewRFX /> },
      { path: "MyItems", element: <MyItems /> },
      { path: "AllItems", element: <AllItems /> },
      { path: "AllItems/:itemId/:operation", element: <AllItems />},
    ]
  }
]);
root.render(
  <React.StrictMode>
    <UserProvider>
      <SelectedItemProvider>
      <RouterProvider router={router} />
      </SelectedItemProvider>
    </UserProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
