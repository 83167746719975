/* Copyright 2023 Amazon.com and its affiliates; all rights reserved. This file is Amazon Web Services Content and may not be duplicated or distributed without permission. */

import React, { useEffect, useState } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';
import { UserContext } from '../context/userContext';
import { UserInfoContextType } from '../@types/user';
import Chart from './components/common/chart';
import Grid from "@cloudscape-design/components/grid";
import {
  colorChartsStatusNeutral,
  colorChartsStatusHigh,
  colorChartsStatusPositive,
  colorChartsThresholdPositive
} from '@cloudscape-design/design-tokens';
 
//Mock imports only for demo purpose

import LineChart from "@cloudscape-design/components/line-chart"; //TO be removed - demo mock only
import { SpaceBetween, Box, Button } from '@cloudscape-design/components';

export default function Home() {

    const apiUrl = 'https://h85kpb567c.execute-api.eu-west-3.amazonaws.com/prod';
    const path = '/getMyItems';

    //Context Information 
    const { user } = React.useContext(UserContext) as UserInfoContextType;

    //State
    const [chartLoading, setChartLoading] = useState(true)
    //ChartInfo overall RFX
    const [data, setData] = useState([]);
    const [innerMetricValue, setInnerMetricValue] = useState('');

    //ChartInfo 
    const [businessData, setBusinessData] = useState([]);
    const [solutionsData, setSolutionsData] = useState([]);
    const [industryData, setIndustryData] = useState([]);
    const [buildData, setBuildData] = useState([]);
    const [cloudCustomerData, setCloudCustomerData] = useState([]);

    

    useEffect(() => {
        const getOwnItems = async () => {
            const token = (await fetchAuthSession()).tokens?.idToken?.toString();
            const body = {
                user: user.username
            };
            const response = await fetch(`${apiUrl}${path}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(body)

            });
            const data = await response.json();
            setInnerMetricValue(String(data.dataset.length));
            
            return data;
        }

        if (user.username !== 'post 1') {
            getOwnItems().then((data) => {
                setChartLoading(false);
                const countObject:any = {}
                const countObjectBusinessCompleted:any = {};
                const countObjectSolutionsCompleted:any = {};
                const countObjectCustomerIndustry:any = {};
                const countObjectBuild:any = {};
                const countObjectCloudCustomer:any = {};
                if(data.dataset.length > 0) {
                    data.dataset.forEach((item: any) => {
                        Object.keys(item).forEach(key => {
                            if (key === "requestType"){
                                countObject[item[key]] = countObject[item[key]] + 1 || 1
                            }
                            if (key === "postQualificationBusiness"){
                                countObjectBusinessCompleted[item[key]] = countObjectBusinessCompleted[item[key]] + 1 || 1
                            }
                            if (key === "postQualificationSolution"){
                                countObjectSolutionsCompleted[item[key]] = countObjectSolutionsCompleted[item[key]] + 1 || 1
                            }
                            if (key === "companyIndustry"){
                                countObjectCustomerIndustry[item[key]] = countObjectCustomerIndustry[item[key]] + 1 || 1
                            }
                            if (key === 'buildVsBuy'){
                                countObjectBuild[item[key]] = countObjectBuild[item[key]] + 1 || 1
                            }
                            if (key === 'cloudCustomer'){
                                countObjectCloudCustomer[item[key]] = countObjectCloudCustomer[item[key]] + 1 || 1
                            }
                                
                        })
                    })
                    const dataSet:any = [];
                    const dataSetBusinessCompleted:any = [];
                    const dataSetSolutionsCompleted:any = [];
                    const dataSetCustomerIndustry:any = [];
                    const dataSetBuild:any = [];
                    const dataSetCloudCustomer:any = [];
                    let color;
                    Object.keys(countObject).forEach(key => {
                        dataSet.push({
                            title: key,
                            value: countObject[key]
                        })
                    })
                    Object.keys(countObjectBusinessCompleted).forEach(key => {
                      key === 'Completed' ? color=colorChartsStatusPositive : color=colorChartsStatusHigh
                        dataSetBusinessCompleted.push({
                            title: key,
                            value: countObjectBusinessCompleted[key],
                            color: color
                        })
                    })
                    Object.keys(countObjectSolutionsCompleted).forEach(key => {
                      key === 'Completed' ? color=colorChartsStatusPositive : color=colorChartsStatusHigh
                        dataSetSolutionsCompleted.push({
                            title: key,
                            value: countObjectSolutionsCompleted[key],
                            color: color
                        })
                    })
                    Object.keys(countObjectCustomerIndustry).forEach(key => {
                        dataSetCustomerIndustry.push({
                            title: key,
                            value: countObjectCustomerIndustry[key]
                        })
                    })
                    Object.keys(countObjectBuild).forEach(key => {
                      key === 'Build' ? color=colorChartsStatusPositive : color=colorChartsStatusHigh
                        dataSetBuild.push({
                            title: key,
                            value: countObjectBuild[key],
                            color: color
                        })
                    })
                    Object.keys(countObjectCloudCustomer).forEach(key => {
                      key === 'Yes' ? color=colorChartsStatusPositive : color=colorChartsStatusHigh
                        dataSetCloudCustomer.push({
                            title: key,
                            value: countObjectCloudCustomer[key],
                            color: color
                        })
                    })
                    setData(dataSet);
                    setBusinessData(dataSetBusinessCompleted);
                    setSolutionsData(dataSetSolutionsCompleted);
                    setIndustryData(dataSetCustomerIndustry);
                    setBuildData(dataSetBuild);
                    setCloudCustomerData(dataSetCloudCustomer);

                } else {
                    console.log(`No data`);
                }
                
            })
        }

    }, [user])

    const content = (
        <>
        <Grid 
        gridDefinition={[ { colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}>
            <Chart 
        data={data}
        innerMetricValue={innerMetricValue}
        chartLabel='RFx Types'
        size='small'
        statusType={chartLoading}
        variant='donut' />
        <Chart
        data={businessData}
        innerMetricValue={innerMetricValue}
        chartLabel='Business Checklists'
        size='small'
        statusType={chartLoading}
        variant='donut' />
         <Chart
        data={solutionsData}
        innerMetricValue={innerMetricValue}
        chartLabel='Solutions Checklists'
        size='small'
        statusType={chartLoading}
        variant='donut' />
        </Grid>
        <Grid 
        gridDefinition={[ { colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}>
        <Chart
        data={industryData}
        innerMetricValue={innerMetricValue}
        chartLabel='By Industry'
        size='small'
        statusType={chartLoading}
        variant='donut' />
        <Chart
        data={buildData}
        innerMetricValue={innerMetricValue}
        chartLabel='Builder or Buyer'
        size='small'
        statusType={chartLoading}
        variant='donut' />
        <Chart
        data={cloudCustomerData}
        innerMetricValue={innerMetricValue}
        chartLabel='Existing Cloud Customer'
        size='small'
        statusType={chartLoading}
        variant='donut' />
        </Grid>

        <Grid 
        gridDefinition={[ { colspan: 1 }, { colspan: 10 }, { colspan: 1 }]}>
            <SpaceBetween size='s' />

            <LineChart
      series={[
        {
          title: "RFx qualified",
          type: "line",
          data: [
            { x: new Date(1722470400000), y: 15 },
            { x: new Date(1725148800000), y: 23 },
            { x: new Date(1727740800000), y: 12 },
            { x: new Date(1730419200000), y: 24 },
            { x: new Date(1733011200000), y: 15 },
            { x: new Date(1735689600000), y: 31 },
            { x: new Date(1738368000000), y: 18 },
            { x: new Date(1740787200000), y: 29 },
            { x: new Date(1743465600000), y: 13 },
            { x: new Date(1746057600000), y: 10 },
            { x: new Date(1748736000000), y: 11 },
            { x: new Date(1751328000000), y: 32 },
            { x: new Date(1754006400000), y: 25 }
          ],
          valueFormatter: function s(e) {
            return Math.abs(e) >= 1e9
              ? (e / 1e9).toFixed(1).replace(/\.0$/, "") +
                  "G"
              : Math.abs(e) >= 1e6
              ? (e / 1e6).toFixed(1).replace(/\.0$/, "") +
                "M"
              : Math.abs(e) >= 1e3
              ? (e / 1e3).toFixed(1).replace(/\.0$/, "") +
                "K"
              : e.toFixed(2);
          }
        },
        {
          title: "RFx Lost",
          type: "line",
          data: [
            { x: new Date(1722470400000), y: 10 },
            { x: new Date(1725148800000), y: 12 },
            { x: new Date(1727740800000), y: 4 },
            { x: new Date(1730419200000), y: 19 },
            { x: new Date(1733011200000), y: 8 },
            { x: new Date(1735689600000), y: 22 },
            { x: new Date(1738368000000), y: 24 },
            { x: new Date(1740787200000), y: 21 },
            { x: new Date(1743465600000), y: 3 },
            { x: new Date(1746057600000), y: 3 },
            { x: new Date(1748736000000), y: 7 },
            { x: new Date(1751328000000), y: 12 },
            { x: new Date(1754006400000), y: 11 }
          ],
          valueFormatter: function s(e) {
            return Math.abs(e) >= 1e9
              ? (e / 1e9).toFixed(1).replace(/\.0$/, "") +
                  "G"
              : Math.abs(e) >= 1e6
              ? (e / 1e6).toFixed(1).replace(/\.0$/, "") +
                "M"
              : Math.abs(e) >= 1e3
              ? (e / 1e3).toFixed(1).replace(/\.0$/, "") +
                "K"
              : e.toFixed(2);
          }
        },
        {
            title: "RFx Win",
            type: "line",
            data: [
                { x: new Date(1722470400000), y: 5 },
                { x: new Date(1725148800000), y: 11 },
                { x: new Date(1727740800000), y: 8 },
                { x: new Date(1730419200000), y: 5 },
                { x: new Date(1733011200000), y: 7 },
                { x: new Date(1735689600000), y: 9 },
                { x: new Date(1738368000000), y: 6 },
                { x: new Date(1740787200000), y: 8 },
                { x: new Date(1743465600000), y: 10 },
                { x: new Date(1746057600000), y: 7 },
                { x: new Date(1748736000000), y: 20 },
                { x: new Date(1751328000000), y: 14 },
                { x: new Date(1754006400000), y: 25 }
            ],
            valueFormatter: function s(e) {
              return Math.abs(e) >= 1e9
                ? (e / 1e9).toFixed(1).replace(/\.0$/, "") +
                    "G"
                : Math.abs(e) >= 1e6
                ? (e / 1e6).toFixed(1).replace(/\.0$/, "") +
                  "M"
                : Math.abs(e) >= 1e3
                ? (e / 1e3).toFixed(1).replace(/\.0$/, "") +
                  "K"
                : e.toFixed(2);
            }
            
          }
      ]}
      ariaLabel="Multiple data series line chart"
      height={300}
      xScaleType="time"
      xTitle="Date"
      yTitle="Count"
      empty={
        <Box textAlign="center" color="inherit">
          <b>No data available</b>
          <Box variant="p" color="inherit">
            There is no data available
          </Box>
        </Box>
      }
      noMatch={
        <Box textAlign="center" color="inherit">
          <b>No matching data</b>
          <Box variant="p" color="inherit">
            There is no matching data to display
          </Box>
          <Button>Clear filter</Button>
        </Box>
      }
    />

            <SpaceBetween size='s' />
        </Grid>
       
         </>
    )

    return content
}