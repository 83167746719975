import React from 'react';
import { UserInfoContextType, UserInfo } from '../@types/user';

export const UserContext = React.createContext<UserInfoContextType | null>(null);

const UserProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
  const [user, setUser] = React.useState<UserInfo>(
    {
      id: 1,
      username: 'post 1',
      usermail: 'not@logged.in',
      loggedIn: false,
      showChangelog: false
    }
  );
  const updateUser = (user: UserInfo) => {
    setUser(user);
  };
  return <UserContext.Provider value={{ user, updateUser }}>{children}</UserContext.Provider>;
};

export default UserProvider;