/* Copyright 2023 Amazon.com and its affiliates; all rights reserved. This file is Amazon Web Services Content and may not be duplicated or distributed without permission. */

import React, { useCallback, useEffect, useState } from 'react';
import {
  Autosuggest,
  Button,
  ButtonDropdown,
  Box,
  BreadcrumbGroup,
  Container,
  ContentLayout,
  CopyToClipboard,
  ColumnLayout,
  DatePicker,
  Flashbar,
  Form,
  FormField,
  Grid,
  Header,
  Input,
  Link,
  Popover,
  RadioGroup,
  SpaceBetween,
  Slider,
  StatusIndicator,
  SplitPanel,
  Spinner,
  TextContent,
  Textarea,
  Icon
} from '@cloudscape-design/components';
import { I18nProvider } from '@cloudscape-design/components/i18n';
import messages from '@cloudscape-design/components/i18n/messages/all.en';
import { UserContext } from '../context/userContext';
import { UserInfoContextType } from '../@types/user';
import { fetchAuthSession } from 'aws-amplify/auth';
import { setAutoSignIn } from '@aws-amplify/auth/dist/esm/providers/cognito/apis/autoSignIn';

export default function NewRFX() {
  const apiUrl = 'https://h85kpb567c.execute-api.eu-west-3.amazonaws.com/prod';
  const path = '/items';

  const LOCALE = 'en';
  const [flashBarItems, setFlashBarItems] = useState<any | null>([
  ]);
  const [breadcrumbs, setBreadcrumbs] = useState([
    { text: 'Home', href: '/' },
    { text: 'New RFx', href: '/NewRFX' },
    { text: 'Intake Type', href: '/NewRFX' }
  ]);



  //Context Information 
  const { user, updateUser } = React.useContext(UserContext) as UserInfoContextType;
  const [newIntakeId, setNewIntakeId] = useState<any>({});


  const [requestType, setRequestType] = React.useState("PQQ");
  //Section visibility
  const [radioActive, setRadioActive] = useState(true);
  const [basicInfoActive, setBasicInfoActive] = useState(false);
  const [customerInfoActive, setCustomerInfoActive] = useState(false);
  const [checklistActive, setChecklistActive] = useState(false);
  const [reviewActive, setReviewActive] = useState(false);
  const [successActive, setSuccessActive] = useState(false);
  const [submitSpinnerActive, setSubmitSpinnerActive] = useState(false);

  //Basic Info Fields
  const [oppyDeadline, setOppyDeadline] = useState("");
  const [awsAccountExec, setAwsAccountExec] = useState("");
  const [salesforceOppyURL, setSalesforceOppyURL] = useState("https://aws-crm.lightning.force.com/lightning/r/Opportunity/[Opportunity ID]");
  const [oppyForecastedDate, setOppyForecastedDate] = useState('');
  const [connectSpecialist, setConnectSpecialist] = useState('');
  const [connectSpecialistSA, setConnectSpecialistSA] = useState(user.username);

  // Customer Info Fields 
  const [companyName, setCompanyName] = useState("");
  const [companyIndustry, setCompanyIndustry] = useState("");
  const [mainOfficeAddress, setMainOfficeAddress] = useState("");
  const [regionCountry, setRegionCountry] = useState("");
  const [primaryBusinessContactName, setPrimaryBusinessContactName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");

  // Checklist Input Fields
  const [frameworkTender, setFrameworkTender] = useState("No");
  const [awsCustomer, setAwsCustomer] = useState("No");
  const [influence, setInfluence] = useState("No");
  const [competition, setCompetition] = useState("No");
  const [accountPlan, setAccountPlan] = useState("No");
  const [opportunitySeatSize, setOpportunitySeatSize] = useState("99");
  const [opportunityType, setOpportunityType] = useState("Digital only");
  const [expectedARR, setExpectedARR] = useState(1);
  const [awsSentiment, setAwsSentiment] = useState("No");
  const [buildVsBuy, setBuildVsBuy] = useState("Build");
  const [effort, setEffort] = useState(2);
  const [partnerLed, setPartnerLed] = useState("Yes");
  const [consultingPartner, setConsultinPartner] = useState("No");

  //Inputs invalid flags
  const [oppyDeadlineInvalid, setOppyDeadlineInvalid] = useState(false);
  const [awsAccountExecInvalid, setAwsAccountExecInvalid] = useState(false);
  const [salesforceOppyURLInvalid, setSalesforceOppyURLInvalid] = useState(false);
  const [oppyForecastedDateInvalid, setOppyForecastedDateInvalid] = useState(false);
  const [connectSpecialistInvalid, setConnectSpecialistInvalid] = useState(false);
  const [connectSpecialistSAInvalid, setConnectSpecialistSAInvalid] = useState(false);

  // AutoComplete Suggestions
  const [suggestionsAwsAccountExec, setSuggestionsAwsAccountExec] = useState<any>([]);
  const [autosuggestStatusAwsAccountExec, setAutosuggestStatusAwsAccountExec] = useState<any>('loading');
  const [suggestionsConnectSpecialist, setSuggestionConnectSpecialist] = useState<any>([]);
  const [autosuggestStatusConnectSpecialist, setAutosuggestStatusConnectSpecialist] = useState<any>('loading');
  const [suggestionsConnectSpecialistSA, setSuggestionConnectSpecialistSA] = useState<any>([]);
  const [autosuggestStatusConnectSpecialistSA, setAutosuggestStatusConnectSpecialistSA] = useState<any>('loading');

  const pathGetEmployeeSuggestionByLogin = '/papiAutoCompleteByLogin';
  const pathGetEmployeeByLogin = '/papiEmployeeByLogin';

  //Text styling
  const hStyle = { color: 'red' };

  //Autocomplete functionality
  

  const getEmployeeSuggestionsByLogin = async (login: string) => {
    const token = (await fetchAuthSession()).tokens?.idToken?.toString();
    const body = {
      login: login
    };
    const response = await fetch(`${apiUrl}${pathGetEmployeeSuggestionByLogin}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(body)

    });
    const data = await response.json();
    return data
  }

  const getEmployeeByLogin = async (login: string) => {
    const token = (await fetchAuthSession()).tokens?.idToken?.toString();
    const body = {
      login: login
    };
    const response = await fetch(`${apiUrl}${pathGetEmployeeByLogin}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(body)

    });
    const data = await response.json();
    return data
  }

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setAutosuggestStatusAwsAccountExec('loading');
      
      const fetchDetails = async () => {
        const employeeDetails = await getEmployeeSuggestionsByLogin(awsAccountExec);
        console.log(`Employee Details: ${JSON.stringify(employeeDetails)}`);
        let array:any = [];
        let arrayDetails:any = [];

        let requests = employeeDetails.suggestions.map((item:string) => {
          return new Promise((resolve) => {
            array.push({value: item });
            resolve(true);
          })
        })


        Promise.all(requests).then(async () => {
          let busTitle = employeeDetails.suggestions.map(async (item:string) => {
            return await getEmployeeByLogin(item);
          })
          Promise.all(busTitle).then((data) => {
            console.log(JSON.stringify(data));
            data.map(item => {
              arrayDetails.push({
                value: item.login,
                description: `${item.firstName} ${item.lastName} - ${item.businessTitle}`
              })
            })
            setSuggestionsAwsAccountExec(arrayDetails);
            setAutosuggestStatusAwsAccountExec('finished');
          })
          
        })
      }
      if (awsAccountExec !== '') {
        console.log(`Try to find Employee suggestions by login ${awsAccountExec}`);
        fetchDetails();
  
      } else {
        console.log(`No Connect Specialist set`);
        setSuggestionsAwsAccountExec([]);
        setAutosuggestStatusAwsAccountExec('finished');
      }
    }, 900);
    return () => clearTimeout(timeOutId);
    

  }, [awsAccountExec])

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setAutosuggestStatusConnectSpecialist('loading');
      
      const fetchDetails = async () => {
        const employeeDetails = await getEmployeeSuggestionsByLogin(connectSpecialist);
        console.log(`Employee Details: ${JSON.stringify(employeeDetails)}`);
        let array:any = [];
        let arrayDetails:any = [];

        let requests = employeeDetails.suggestions.map((item:string) => {
          return new Promise((resolve) => {
            array.push({value: item });
            resolve(true);
          })
        })


        Promise.all(requests).then(async () => {
          let busTitle = employeeDetails.suggestions.map(async (item:string) => {
            return await getEmployeeByLogin(item);
          })
          Promise.all(busTitle).then((data) => {
            console.log(JSON.stringify(data));
            data.map(item => {
              arrayDetails.push({
                value: item.login,
                description: `${item.firstName} ${item.lastName} - ${item.businessTitle}`
              })
            })
            setSuggestionConnectSpecialist(arrayDetails);
            setAutosuggestStatusConnectSpecialist('finished');
          })
          
        })
      }
      if (connectSpecialist !== '') {
        console.log(`Try to find Employee suggestions by login ${connectSpecialist}`);
        fetchDetails();
  
      } else {
        console.log(`No Connect Specialist set`);
        setSuggestionConnectSpecialist([]);
        setAutosuggestStatusConnectSpecialist('finished')
      }
    }, 900);
    return () => clearTimeout(timeOutId);
    

  }, [connectSpecialist])

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setAutosuggestStatusConnectSpecialistSA('loading');
      
      const fetchDetails = async () => {
        const employeeDetails = await getEmployeeSuggestionsByLogin(connectSpecialistSA);
        console.log(`Employee Details: ${JSON.stringify(employeeDetails)}`);
        let array:any = [];
        let arrayDetails:any = [];

        let requests = employeeDetails.suggestions.map((item:string) => {
          return new Promise((resolve) => {
            array.push({value: item });
            resolve(true);
          })
        })


        Promise.all(requests).then(async () => {
          let busTitle = employeeDetails.suggestions.map(async (item:string) => {
            return await getEmployeeByLogin(item);
          })
          Promise.all(busTitle).then((data) => {
            console.log(JSON.stringify(data));
            data.map(item => {
              arrayDetails.push({
                value: item.login,
                description: `${item.firstName} ${item.lastName} - ${item.businessTitle}`
              })
            })
            setSuggestionConnectSpecialistSA(arrayDetails);
            setAutosuggestStatusConnectSpecialistSA('finished');
          })
          
        })
      }
      if (connectSpecialistSA !== '') {
        console.log(`Try to find Employee suggestions by login ${awsAccountExec}`);
        fetchDetails();
  
      } else {
        console.log(`No Connect Specialist set`);
        setSuggestionConnectSpecialist([]);
        setAutosuggestStatusConnectSpecialistSA('finished')
      }
    }, 900);
    return () => clearTimeout(timeOutId);
    

  }, [connectSpecialistSA])

  const onRequestTypeChange = useCallback((event: any) => {
    setRequestType(event.detail.value);
    console.log(`Value selected by useCallback: ${event.detail.value}`);
  }, []);

  const firstClick = () => {
    console.log('first Option clicked');
    setRadioActive(false);
    setBasicInfoActive(true);
    setBreadcrumbs([
      { text: 'Home', href: '/' },
      { text: 'New RFx', href: '/NewRFX' },
      { text: 'Intake Type', href: '/#' },
      { text: 'Basic Information', href: '/#' }
    ])
    setConnectSpecialistSA(user.username);
  }

  const content = (
    <>
      <I18nProvider locale={LOCALE} messages={[messages]}>
        <ContentLayout>
          <Flashbar items={flashBarItems} />
          {radioActive == true && (

            <Container
              header={
                <Header
                  variant="h2"
                  description="Basic Information about the type and size of the new Intake"
                >
                  Pre-Qualification Checklist
                  <BreadcrumbGroup
                    items={breadcrumbs}
                  />
                </Header>
              }
            >
              <Form
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button formAction="none" variant="link">
                      Cancel
                    </Button>
                    <Button variant="primary" onClick={firstClick}>Next</Button>
                  </SpaceBetween>
                }
                header={<Header variant="h1">Intake type</Header>}
              >
                <FormField label="Opportunity Stage">

                  <RadioGroup
                    //  onChange={({ detail }) => setRequestType(detail.value)}
                    onChange={onRequestTypeChange}
                    value={requestType}
                    items={[
                      {
                        value: "PQQ",
                        label:
                          "PQQ",
                        description: "Pre-Qualification Questionaire"
                      },
                      {
                        value: "RFI",
                        label:
                          "RFI",
                        description: "Request for Information"
                      },
                      {
                        value: "RFP",
                        label: "RFP",
                        description: "Request for Proposal"
                      },
                      {
                        value: "NewOpportunity",
                        label: "New Opportunity",
                        description: "A new opportunity intake"
                      }
                    ]}
                  />
                </FormField>
              </Form>


            </Container>
          )}
          {basicInfoActive == true && (
            <Container

              header={
                <Header
                  variant="h2"
                  description="Basic Information about the type and size of the new Intake"
                >
                  Pre-Qualification Checklist
                  <BreadcrumbGroup
                    items={breadcrumbs}
                    onClick={(element) => {
                      console.log(`Breadcrumb clicked ${JSON.stringify(element)}`);
                      switch (element.detail.item.text) {
                        case "New RFx":
                          setRadioActive(true);
                          setBasicInfoActive(false);
                          setCustomerInfoActive(false);
                          break;
                        case "Intake Type":
                          setRadioActive(true);
                          setBasicInfoActive(false);
                          setCustomerInfoActive(false);
                          break;
                          break;
                        default:
                          break;
                      }
                    }}
                  />
                </Header>
              }
            >
              <form onSubmit={e => e.preventDefault()}>
                <Form
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button formAction="none" variant="link" onClick={() => {
                        setRadioActive(true);
                        setBasicInfoActive(false);
                      }}>
                        Back
                      </Button>
                      <Button variant="primary" onClick={(e) => {
                        console.log(`
                        Form submitted.`);
                        if (oppyDeadline.length === 0 || awsAccountExec.length === 0 || oppyForecastedDate.length === 0) {
                          setFlashBarItems([{
                            type: 'error',
                            dismissible: true,
                            header: "Failed to verify intake",
                            content: 'One or more Items are missing.',
                            onDismiss: () => {
                              console.log('Flash message dismissed');
                              setFlashBarItems([])
                            },
                          }]);
                          setTimeout(() => {
                            setFlashBarItems([]);
                          }, 7000);
                          if (oppyDeadline.length === 0) setOppyDeadlineInvalid(true);
                          if (awsAccountExec.length === 0) setAwsAccountExecInvalid(true);
                          if (oppyForecastedDate.length === 0) setOppyForecastedDateInvalid(true);
                        } else {
                          setBasicInfoActive(false);
                          setCustomerInfoActive(true);
                          setBreadcrumbs([
                            { text: 'Home', href: '/' },
                            { text: 'New RFx', href: '/NewRFX' },
                            { text: 'Intake Type', href: '/NewRFX' },
                            { text: 'Basic Information', href: '/NewRFX' },
                            { text: 'Customer Information', href: '/NewRFX' }
                          ])
                        }

                      }}>Next</Button>
                    </SpaceBetween>
                  }
                >
                  <Container
                    header={
                      <Header variant="h2">
                        Basic Info
                      </Header>
                    }
                  >
                    <SpaceBetween direction="vertical" size="m">
                      <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                        <FormField label={<span>
                          Deadline<span style={hStyle}>*</span>{" "}
                        </span>} description="What is the deadline for completion (in working days) ?">
                          <Input value={oppyDeadline} onChange={({ detail }) => {
                            setOppyDeadline(detail.value)
                            setOppyDeadlineInvalid(false)
                          }} invalid={oppyDeadlineInvalid} />
                        </FormField>
                        <FormField label={<span>
                          Intake Owner{" "}
                        </span>} description="Automatically set by your federated login">
                          <Input value={user.username} disabled />
                        </FormField>
                      </Grid>
                      <Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}>
                        <FormField label={<span>
                          AWS Account Executive<span style={hStyle}>*</span>{" "}
                        </span>
                        }
                          description="Please use alias format without @">
                          <Autosuggest
                            onChange={({ detail }) => {
                                setAwsAccountExec(detail.value);
                              }
                            }
                            loadingText="Loading results.."
                            statusType={autosuggestStatusAwsAccountExec}
                            value={awsAccountExec}
                            options={suggestionsAwsAccountExec}
                            ariaLabel="Autosuggest example with suggestions"
                            placeholder="Enter value"
                            empty="No matches found"
                          />
                          
                        </FormField>
                        <FormField label={<span>
                          AWS Connect Specialist<span style={hStyle}>*</span>{" "}
                        </span>
                        }
                          description="Please use alias format without @">
                            <Autosuggest
                            onChange={({ detail }) => {
                                setConnectSpecialist(detail.value);
                              }
                            }
                            loadingText="Loading results.."
                            statusType={autosuggestStatusConnectSpecialist}
                            value={connectSpecialist}
                            options={suggestionsConnectSpecialist}
                            ariaLabel="Autosuggest example with suggestions"
                            placeholder="Enter value"
                            empty="No matches found"
                          />
                          
                        </FormField>
                        <FormField label={<span>
                          AWS Connect Specialist SA<span style={hStyle}>*</span>{" "}
                        </span>}
                          description="Please use alias format without @">
                            <Autosuggest
                            onChange={({ detail }) => {
                                setConnectSpecialistSA(detail.value);
                              }
                            }
                            loadingText="Loading results.."
                            statusType={autosuggestStatusConnectSpecialistSA}
                            value={connectSpecialistSA}
                            options={suggestionsConnectSpecialistSA}
                            ariaLabel="Autosuggest example with suggestions"
                            placeholder="Enter value"
                            empty="No matches found"
                          />
                        </FormField>
                      </Grid>
                      <Grid gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}>
                        <FormField label={<span>
                          Salesforce Opportunity ID<span style={hStyle}>*</span>{" "}
                        </span>} >
                          <Input value={salesforceOppyURL} onChange={({ detail }) => {
                            setSalesforceOppyURL(detail.value)
                            setSalesforceOppyURLInvalid(false)
                          }} inputMode='url' invalid={salesforceOppyURLInvalid} />
                        </FormField>
                        <FormField label={<span>
                          Opportunity Forecast Date<span style={hStyle}>*</span>{" "}
                        </span>}>
                          <DatePicker placeholder='YYYY/MM/DD' value={oppyForecastedDate} onChange={({ detail }) => {
                            setOppyForecastedDate(detail.value)
                            setOppyForecastedDateInvalid(false)
                          }} invalid={oppyForecastedDateInvalid} />
                        </FormField>
                      </Grid>
                    </SpaceBetween>
                  </Container>
                </Form>
              </form>
            </Container>
          )}
          {customerInfoActive == true && (
            <Container
              header={
                <Header
                  variant="h2"
                  description="Basic Information about the type and size of the new Intake"
                >
                  Pre-Qualification Checklist
                  <BreadcrumbGroup
                    items={breadcrumbs}
                    onClick={(element) => {
                      if (element.detail.item.text !== "Home") element.preventDefault();
                      switch (element.detail.item.text) {
                        case "New RFx":
                          setRadioActive(true);
                          setBasicInfoActive(false);
                          setCustomerInfoActive(false);
                          break;
                        case "Intake Type":
                          setRadioActive(true);
                          setBasicInfoActive(false);
                          setCustomerInfoActive(false);
                          setBreadcrumbs([
                            { text: 'Home', href: '/' },
                            { text: 'New RFx', href: '/NewRFX' },
                            { text: 'Intake Type', href: '/#' }
                          ])
                          break;
                        case "Basic Information":
                          setRadioActive(false);
                          setBasicInfoActive(true);
                          setCustomerInfoActive(false);
                          setBreadcrumbs([
                            { text: 'Home', href: '/' },
                            { text: 'New RFx', href: '/NewRFX' },
                            { text: 'Intake Type', href: '/#' },
                            { text: 'Basic Information', href: '/#' }
                          ])
                          break;
                        default:
                          break;
                      }
                    }}
                  />
                </Header>
              }
            >
              <form onSubmit={e => e.preventDefault()}>
                <Form
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button formAction="none" variant="link" onClick={() => {
                        setRadioActive(false);
                        setBasicInfoActive(true);
                        setCustomerInfoActive(false);
                      }}>
                        Back
                      </Button>
                      <Button variant="primary" onClick={(e) => {
                        setBreadcrumbs([
                          { text: 'Home', href: '/' },
                          { text: 'New RFx', href: '/NewRFX' },
                          { text: 'Intake Type', href: '/NewRFX' },
                          { text: 'Basic Information', href: '/NewRFX' },
                          { text: 'Customer Information', href: '/NewRFX' },
                          { text: 'Checklist', href: '/NewRFX' }
                        ])
                        setRadioActive(false);
                        setBasicInfoActive(false);
                        setCustomerInfoActive(false);
                        setChecklistActive(true);

                      }}>Next</Button>
                    </SpaceBetween>
                  }
                >
                  <Container
                    header={
                      <Header variant="h2">
                        Customer Information
                      </Header>
                    }
                  >
                    <SpaceBetween direction="horizontal" size="xl">
                      <FormField label="Company Name">
                        <Input value={companyName} onChange={({ detail }) => {
                          setCompanyName(detail.value)
                        }} />
                      </FormField>
                      <FormField label="Company Industry">
                        <Input value={companyIndustry} onChange={({ detail }) => {
                          setCompanyIndustry(detail.value)
                        }} />
                      </FormField>
                      <FormField label="Region / Country">
                        <Input value={regionCountry} onChange={({ detail }) => {
                          setRegionCountry(detail.value)
                        }} />
                      </FormField>
                    </SpaceBetween>
                    <SpaceBetween direction='vertical' size="xl">
                      <FormField label="Main Office Address">
                        <Textarea value={mainOfficeAddress} onChange={({ detail }) => {
                          setMainOfficeAddress(detail.value)
                        }} />
                      </FormField>
                      <SpaceBetween direction="horizontal" size="xl">
                        <FormField label="Primary Business Contact Name">
                          <Input value={primaryBusinessContactName} onChange={({ detail }) => {
                            setPrimaryBusinessContactName(detail.value)
                          }} />
                        </FormField>
                        <FormField label="Phone Number">
                          <Input value={phoneNumber} onChange={({ detail }) => {
                            setPhoneNumber(detail.value)
                          }} inputMode="tel" />
                        </FormField>
                        <FormField label="Email Address">
                          <Input value={emailAddress} onChange={({ detail }) => {
                            setEmailAddress(detail.value)
                          }} inputMode='email' />
                        </FormField>
                      </SpaceBetween>
                    </SpaceBetween>
                  </Container>
                </Form>
              </form>
            </Container>
          )}
          {checklistActive == true && (
            <Container
              header={
                <Header
                  variant="h2"
                  description="Basic Information about the type and size of the new Intake"
                >
                  Pre-Qualification Checklist
                  <BreadcrumbGroup
                    items={breadcrumbs}
                    onClick={(element) => {
                      if (element.detail.item.text !== "Home") element.preventDefault();
                      console.log(`Breadcrumb clicked ${JSON.stringify(element)}`);
                      switch (element.detail.item.text) {
                        case "New RFx":
                          setRadioActive(true);
                          setBasicInfoActive(false);
                          setCustomerInfoActive(false);
                          break;
                        case "Intake Type":
                          setRadioActive(true);
                          setBasicInfoActive(false);
                          setCustomerInfoActive(false);
                          setBreadcrumbs([
                            { text: 'Home', href: '/' },
                            { text: 'New RFx', href: '/NewRFX' },
                            { text: 'Intake Type', href: '/#' }
                          ])
                          break;
                        case "Basic Information":
                          setRadioActive(false);
                          setBasicInfoActive(true);
                          setCustomerInfoActive(false);
                          setBreadcrumbs([
                            { text: 'Home', href: '/' },
                            { text: 'New RFx', href: '/NewRFX' },
                            { text: 'Intake Type', href: '/#' },
                            { text: 'Basic Information', href: '/#' }
                          ])
                          break;
                        case "Customer Information":
                          setRadioActive(false);
                          setBasicInfoActive(false);
                          setCustomerInfoActive(true);
                          setChecklistActive(false);
                          setBreadcrumbs([
                            { text: 'Home', href: '/' },
                            { text: 'New RFx', href: '/NewRFX' },
                            { text: 'Intake Type', href: '/#' },
                            { text: 'Basic Information', href: '/#' },
                            { text: 'Customer Information', href: '/#' }
                          ])
                          break;
                        default:
                          break;
                      }
                    }}
                  />
                </Header>
              }
            >
              <form onSubmit={e => e.preventDefault()}>
                <Form
                  actions={
                    <SpaceBetween direction="horizontal" size="s">
                      <Button formAction="none" variant="link" onClick={() => {
                        setRadioActive(false);
                        setBasicInfoActive(false);
                        setCustomerInfoActive(true);
                        setChecklistActive(false);
                        setBreadcrumbs([
                          { text: 'Home', href: '/' },
                          { text: 'New RFx', href: '/NewRFX' },
                          { text: 'Intake Type', href: '/#' },
                          { text: 'Basic Information', href: '/#' },
                          { text: 'Customer Information', href: '/#' }
                        ])
                      }}>
                        Back
                      </Button>
                      <Button variant="primary" onClick={(e) => {
                        setBreadcrumbs([
                          { text: 'Home', href: '/' },
                          { text: 'New RFx', href: '/NewRFX' },
                          { text: 'Intake Type', href: '/#' },
                          { text: 'Basic Information', href: '/#' },
                          { text: 'Customer Information', href: '/#' },
                          { text: 'Checklist', href: '/#' },
                          { text: 'Review & Submit', href: '/#' }
                        ])
                        setRadioActive(false);
                        setBasicInfoActive(false);
                        setCustomerInfoActive(false);
                        setChecklistActive(false);
                        setReviewActive(true);
                      }}>Next</Button>
                    </SpaceBetween>
                  }
                >
                  <Container
                    header={
                      <Header variant="h2">
                        Checklist Intake
                      </Header>
                    }
                  >
                    <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                      <FormField label="Framework Tender" description="Does this tender fall within a tender framework that we have access to?">
                        <ButtonDropdown
                          items={[
                            { text: "Yes", id: "Yes", disabled: false },
                            { text: "No", id: "No", disabled: false }
                          ]}
                          onItemClick={(event) => {
                            console.log(`The user clicked on the ${JSON.stringify(event.detail.id)} menu item`);
                            setFrameworkTender(event.detail.id);
                          }}
                        >{frameworkTender} </ButtonDropdown>
                      </FormField>
                      <FormField label="AWS Customer" description="Is this customer an existing AWS customer?">
                        <ButtonDropdown
                          items={[
                            { text: "Yes", id: "Yes", disabled: false },
                            { text: "No", id: "No", disabled: false }
                          ]}
                          onItemClick={(event) => {
                            console.log(`The user clicked on the ${JSON.stringify(event.detail.id)} menu item`);
                            setAwsCustomer(event.detail.id);
                          }}
                        >{awsCustomer} </ButtonDropdown>
                      </FormField>
                    </Grid>
                    <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                      <FormField label="Influence" description="Have we been involved in the process to influence the RFP">
                        <ButtonDropdown
                          items={[
                            { text: "Yes", id: "Yes", disabled: false },
                            { text: "No", id: "No", disabled: false }
                          ]}
                          onItemClick={(event) => {
                            console.log(`The user clicked on the ${JSON.stringify(event.detail.id)} menu item`);
                            setInfluence(event.detail.id);
                          }}
                        >{influence} </ButtonDropdown>
                      </FormField>
                      <FormField label="Competition" description="Do we know who the competition are?">
                        <ButtonDropdown
                          items={[
                            { text: "Yes", id: "Yes", disabled: false },
                            { text: "No", id: "No", disabled: false }
                          ]}
                          onItemClick={(event) => {
                            console.log(`The user clicked on the ${JSON.stringify(event.detail.id)} menu item`);
                            setCompetition(event.detail.id);
                          }}
                        >{competition} </ButtonDropdown>
                      </FormField>
                    </Grid>
                    <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                      <FormField label="Account Plan" description="Do we have an account plan?">
                        <ButtonDropdown
                          items={[
                            { text: "Yes", id: "Yes", disabled: false },
                            { text: "No", id: "No", disabled: false }
                          ]}
                          onItemClick={(event) => {
                            console.log(`The user clicked on the ${JSON.stringify(event.detail.id)} menu item`);
                            setAccountPlan(event.detail.id);
                          }}
                        >{accountPlan} </ButtonDropdown>
                      </FormField>
                      <FormField label="Opportunity Type" description="What is the mix of digital and voice channels within this opportunity?">
                        <ButtonDropdown
                          items={[
                            { text: "Digital Only", id: "Digital Only", disabled: false },
                            { text: "Voice Only", id: "Voice Only", disabled: false },
                            { text: "Voice + Digital", id: "Voice + Digital", disabled: false }
                          ]}
                          onItemClick={(event) => {
                            console.log(`The user clicked on the ${JSON.stringify(event.detail.id)} menu item`);
                            setOpportunityType(event.detail.id);
                          }}
                        >{opportunityType} </ButtonDropdown>
                      </FormField>
                    </Grid>
                    <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                      <FormField label="Opportunity seat size" description="How many contact centre seats does this customer have?">
                        <Input value={opportunitySeatSize} onChange={({ detail }) => {
                          setOpportunitySeatSize(detail.value)
                        }} inputMode="numeric" />
                      </FormField>
                      <FormField label="Expected ARR in Million $" description="What is the expected ARR for this opportunity in Millions (Examples, $1.1 or $0.7)">
                        <Input value={String(expectedARR)} onChange={({ detail }) => {
                          setExpectedARR(Number(detail.value.replace(',', '.')))
                        }} inputMode="decimal" type='number' step={0.1} />
                      </FormField>
                    </Grid>
                    <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                      <FormField label="AWS Sentiment" description="Is the decision maker bought into AWS (i.e. champion, all-in)?">
                        <ButtonDropdown
                          items={[
                            { text: "Yes", id: "Yes", disabled: false },
                            { text: "No", id: "No", disabled: false }
                          ]}
                          onItemClick={(event) => {
                            console.log(`The user clicked on the ${JSON.stringify(event.detail.id)} menu item`);
                            setAwsSentiment(event.detail.id);
                          }}
                        >{awsSentiment} </ButtonDropdown>
                      </FormField>
                      <FormField label="Build vS. Buy" description="Does the business have a build or buy mentality?">
                        <ButtonDropdown
                          items={[
                            { text: "Build", id: "Build", disabled: false },
                            { text: "Buy", id: "Buy", disabled: false }
                          ]}
                          onItemClick={(event) => {
                            console.log(`The user clicked on the ${JSON.stringify(event.detail.id)} menu item`);
                            setBuildVsBuy(event.detail.id);
                          }}
                        >{buildVsBuy} </ButtonDropdown>
                      </FormField>
                    </Grid>
                    <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                      <FormField label="Partner led?" description="Is this partner led and who is the partner?">
                        <ButtonDropdown
                          items={[
                            { text: "Yes", id: "Yes", disabled: false },
                            { text: "No", id: "No", disabled: false }
                          ]}
                          onItemClick={(event) => {
                            console.log(`The user clicked on the ${JSON.stringify(event.detail.id)} menu item`);
                            setPartnerLed(event.detail.id);
                          }}
                        >{partnerLed} </ButtonDropdown>
                      </FormField>
                      <FormField label="Consulting Partner?" description="If this is consulting led, are they promoters of AWS?">
                        <ButtonDropdown
                          items={[
                            { text: "Yes", id: "Yes", disabled: false },
                            { text: "No", id: "No", disabled: false }
                          ]}
                          onItemClick={(event) => {
                            console.log(`The user clicked on the ${JSON.stringify(event.detail.id)} menu item`);
                            setConsultinPartner(event.detail.id);
                          }}
                        >{consultingPartner} </ButtonDropdown>
                      </FormField>
                    </Grid>
                    <Grid gridDefinition={[{ colspan: 2 }, { colspan: 8 }, { colspan: 2 }]}>
                      <SpaceBetween size='s'></SpaceBetween>
                      <FormField label="Estimated effort to respond" description="What is the estimated effort to respond (Please consider end to end efforts until final outcome i.e. demo etc)?"
                        info={<Popover
                          dismissButton={true}
                          header="Estimate effort to respond"
                          content={
                            <TextContent>
                              <h4>RFI</h4>
                              <small>
                                High Effort = above 5 Days<br />
                                Medium Effort = 3-5 days<br />
                                Low Effort = less than 3 days<br />
                              </small> <p />
                              <h4>RFP</h4>
                              <small>
                                High Effort = 10+ Days<br />
                                Medium Effort = 5-10 days<br />
                                Low Effort = less than5 days <br />
                              </small>
                            </TextContent>

                          }
                        >

                          <Link variant="info">Info</Link></Popover>}  >

                        <Slider
                          onChange={({ detail }) => { setEffort(detail.value) }}
                          value={effort}
                          valueFormatter={value =>
                            [
                              { value: "0", label: "None" },
                              { value: "1", label: "Low" },
                              { value: "2", label: "Medium" },
                              { value: "3", label: "High" }
                            ].find(item => item.value === value.toString())
                              ?.label || ""
                          }
                          ariaDescription="From None to High"
                          max={3}
                          min={0}
                          referenceValues={[1, 2]}
                          step={1}
                          tickMarks

                        />
                      </FormField>
                      <SpaceBetween size='s'></SpaceBetween>
                    </Grid>

                  </Container>
                </Form>
              </form>
            </Container>
          )}
          {reviewActive == true && (
            <Container
              header={
                <Header
                  variant="h2"
                  description="Summary of your new Intake"
                >
                  Pre-Qualification Checklist
                  <BreadcrumbGroup
                    items={breadcrumbs}
                    onClick={(element) => {
                      if (element.detail.item.text !== "Home") element.preventDefault();
                      console.log(`Breadcrumb clicked ${JSON.stringify(element)}`);
                      switch (element.detail.item.text) {
                        case "New RFx":
                          setRadioActive(true);
                          setBasicInfoActive(false);
                          setCustomerInfoActive(false);
                          break;
                        case "Intake Type":
                          setRadioActive(true);
                          setBasicInfoActive(false);
                          setCustomerInfoActive(false);
                          setBreadcrumbs([
                            { text: 'Home', href: '/' },
                            { text: 'New RFx', href: '/NewRFX' },
                            { text: 'Intake Type', href: '/#' }
                          ])
                          break;
                        case "Basic Information":
                          setRadioActive(false);
                          setBasicInfoActive(true);
                          setCustomerInfoActive(false);
                          setBreadcrumbs([
                            { text: 'Home', href: '/' },
                            { text: 'New RFx', href: '/NewRFX' },
                            { text: 'Intake Type', href: '/#' },
                            { text: 'Basic Information', href: '/#' }
                          ])
                          break;
                        case "Customer Information":
                          setRadioActive(false);
                          setBasicInfoActive(false);
                          setCustomerInfoActive(true);
                          setChecklistActive(false);
                          setBreadcrumbs([
                            { text: 'Home', href: '/' },
                            { text: 'New RFx', href: '/NewRFX' },
                            { text: 'Intake Type', href: '/#' },
                            { text: 'Basic Information', href: '/#' },
                            { text: 'Customer Information', href: '/#' }
                          ])
                          break;
                        default:
                          break;
                      }
                    }}
                  />
                </Header>
              }
            >
              <form onSubmit={e => e.preventDefault()}>
                <Form
                  actions={
                    <SpaceBetween direction="horizontal" size="s">
                      <Button formAction="none" variant="link" onClick={() => {
                        setRadioActive(false);
                        setBasicInfoActive(false);
                        setCustomerInfoActive(false);
                        setChecklistActive(true);
                        setReviewActive(false);
                        setBreadcrumbs([
                          { text: 'Home', href: '/' },
                          { text: 'New RFx', href: '/NewRFX' },
                          { text: 'Intake Type', href: '/#' },
                          { text: 'Basic Information', href: '/#' },
                          { text: 'Customer Information', href: '/#' },
                          { text: 'Checklist', href: '/#' }
                        ])
                      }}>
                        Back
                      </Button>
                      {submitSpinnerActive === true && (
                        <Button variant='primary' disabled><Spinner />Submit</Button>
                      )}
                      {submitSpinnerActive === false && (
                        <Button variant="primary" onClick={async (e) => {
                          setSubmitSpinnerActive(true);
                          const object = {
                            oppyDeadline: oppyDeadline,
                            awsAccountExec: awsAccountExec,
                            salesforceOppyURL: salesforceOppyURL,
                            oppyForecastedDate: oppyForecastedDate,
                            connectSpecialist: connectSpecialist,
                            connectSpecialistSA: connectSpecialistSA,
                            companyName: companyName,
                            companyIndustry: companyIndustry,
                            regionCountry: regionCountry,
                            primaryBusinessContactName: primaryBusinessContactName,
                            phoneNumber: phoneNumber,
                            emailAddress: emailAddress,
                            mainOfficeAddress: mainOfficeAddress,
                            frameworkTender: frameworkTender,
                            awsCustomer: awsCustomer,
                            influence: influence,
                            competition: competition,
                            accountPlan: accountPlan,
                            opportunitySeatSize: opportunitySeatSize,
                            opportunityType: opportunityType,
                            expectedARR: expectedARR,
                            awsSentiment: awsSentiment,
                            buildVsBuy: buildVsBuy,
                            effort: effort,
                            partnerLed: partnerLed,
                            consultingPartner: consultingPartner,
                            owner: user.username,
                            requestType: requestType
                          }
                          console.log(JSON.stringify(object));
                          const token = (await fetchAuthSession()).tokens?.idToken?.toString();
                          console.log(token);
                          const response = await fetch(apiUrl + path, {
                            method: 'POST',
                            headers: {
                              'Content-Type': 'application/json',
                              'Authorization': `Bearer ${token}`
                            },
                            body: JSON.stringify(object)
                          });
                          const body = await response.json();
                          console.log(JSON.stringify(body));


                          if (response.status == 200) {
                            setRadioActive(false);
                            setBasicInfoActive(false);
                            setCustomerInfoActive(false);
                            setChecklistActive(false);
                            setReviewActive(false);
                            setSubmitSpinnerActive(false);
                            setNewIntakeId(body);
                            setSuccessActive(true);

                          }
                        }}>Submit</Button>
                      )}

                    </SpaceBetween>
                  }
                >
                  <SpaceBetween size="xl" direction='vertical'>
                    <ColumnLayout columns={4} variant="text-grid">
                      <SpaceBetween size="l">
                        <div>
                          <Box variant="awsui-key-label">Customer Name</Box>
                          <div>{companyName}</div>
                        </div>
                        <div>
                          <Box variant="awsui-key-label">Industry</Box>
                          <div>{companyIndustry}</div>
                        </div>
                        <div>
                          <Box variant="awsui-key-label">Region</Box>
                          <div>{regionCountry}</div>
                        </div>
                      </SpaceBetween>

                      <SpaceBetween size="l">
                        <div>
                          <Box variant="awsui-key-label">Primary Business Contact</Box>
                          <div>{primaryBusinessContactName}</div>
                        </div>
                        <div>
                          <Box variant="awsui-key-label">Phone Number</Box>
                          <div>{phoneNumber}</div>
                        </div>
                        <div>
                          <Box variant="awsui-key-label">E-Mail address</Box>
                          <div>{emailAddress}</div>
                        </div>
                      </SpaceBetween>

                      <SpaceBetween size="l">
                        <div>
                          <Box variant="awsui-key-label">Opportunity Type</Box>
                          <div>{opportunityType}</div>
                        </div>
                        <div>
                          <Box variant="awsui-key-label">Opportunity Deadline in days</Box>
                          <div>{oppyDeadline}</div>
                        </div>
                        <div>
                          <Box variant="awsui-key-label">Opportunity Forecast Date</Box>
                          <div>{oppyForecastedDate}</div>
                        </div>
                      </SpaceBetween>

                      <SpaceBetween size="l">
                        <div>
                          <Box variant="awsui-key-label">AWS Account Executive</Box>
                          <div>{awsAccountExec}</div>
                        </div>
                        <div>
                          <Box variant="awsui-key-label">AWS Connect Specialist</Box>
                          <div>{connectSpecialist}</div>
                        </div>
                        <div>
                          <Box variant="awsui-key-label">AWS Connect Specialist SA</Box>
                          <div>{connectSpecialistSA}</div>
                        </div>
                      </SpaceBetween>
                    </ColumnLayout>
                    <SplitPanel header='Opportunity Information' hidePreferencesButton>
                      <ColumnLayout columns={4} variant="text-grid">
                        <SpaceBetween size="l">
                          <div>
                            <Box variant="awsui-key-label">Framework tender</Box>
                            <StatusIndicator type={frameworkTender == 'Yes' ? 'success' : 'warning'}>{frameworkTender}</StatusIndicator>
                          </div>
                          <div>
                            <Box variant="awsui-key-label">AWS Customer</Box>
                            <StatusIndicator type={awsCustomer == 'Yes' ? 'success' : 'warning'}>{awsCustomer}</StatusIndicator>
                          </div>
                          <div>
                            <Box variant="awsui-key-label">Influence</Box>
                            <StatusIndicator type={influence == 'Yes' ? 'success' : 'warning'}>{influence}</StatusIndicator>
                          </div>
                          <div>
                            <Box variant="awsui-key-label">Competition</Box>
                            <StatusIndicator type={competition == 'Yes' ? 'success' : 'warning'}>{competition}</StatusIndicator>
                          </div>
                        </SpaceBetween>

                        <SpaceBetween size="l">
                          <div>
                            <Box variant="awsui-key-label">Account Plan</Box>
                            <StatusIndicator type={accountPlan == 'Yes' ? 'success' : 'warning'}>{accountPlan}</StatusIndicator>
                          </div>
                          <div>
                            <Box variant="awsui-key-label">Seat size</Box>
                            <StatusIndicator type={Number(opportunitySeatSize) > 100 ? 'success' : 'warning'}>{opportunitySeatSize}</StatusIndicator>
                          </div>
                          <div>
                            <Box variant="awsui-key-label">Opportunity Type</Box>
                            <StatusIndicator type={opportunityType == 'Digital Only' ? 'warning' : 'success'}>{opportunityType}</StatusIndicator>
                          </div>
                          <div>
                            <Box variant="awsui-key-label">Expected ARR</Box>
                            <StatusIndicator type={Number(expectedARR) < 0.5 ? 'warning' : 'success'}>{expectedARR}M USD</StatusIndicator>
                          </div>
                        </SpaceBetween>

                        <SpaceBetween size="l">
                          <div>
                            <Box variant="awsui-key-label">AWS Sentiment</Box>
                            <StatusIndicator type={awsSentiment == 'Yes' ? 'success' : 'warning'}>{awsSentiment}</StatusIndicator>
                          </div>
                          <div>
                            <Box variant="awsui-key-label">Builder or Buyer</Box>
                            <StatusIndicator type={buildVsBuy == 'Build' ? 'success' : 'warning'}>{buildVsBuy}</StatusIndicator>
                          </div>
                          <div>
                            <Box variant="awsui-key-label">Partner led</Box>
                            <StatusIndicator type={partnerLed == 'Yes' ? 'success' : 'warning'}>{partnerLed}</StatusIndicator>
                          </div>
                          <div>
                            <Box variant="awsui-key-label">Consulting Partner</Box>
                            <StatusIndicator type={consultingPartner == 'Yes' ? 'success' : 'warning'}>{consultingPartner}</StatusIndicator>
                          </div>
                        </SpaceBetween>

                        <SpaceBetween size="l">
                          <Box variant="awsui-key-label">Estimated Effort</Box>
                          <Slider
                            warning={(effort === 3 ? true : false)}
                            value={effort}
                            valueFormatter={value =>
                              [
                                { value: "0", label: "None" },
                                { value: "1", label: "Low" },
                                { value: "2", label: "Medium" },
                                { value: "3", label: "High" }
                              ].find(item => item.value === value.toString())
                                ?.label || ""
                            }
                            ariaDescription="From None to High"
                            max={3}
                            min={0}
                            referenceValues={[1, 2]}
                            step={1}
                            tickMarks />
                        </SpaceBetween>
                      </ColumnLayout>
                    </SplitPanel>
                  </SpaceBetween>
                </Form>
              </form>
            </Container>
          )}
          {successActive == true && (
            <Container
              header={
                <Header
                  variant="h2"
                  description="Intake completed"
                >
                  Pre-Qualification Checklist
                  <BreadcrumbGroup
                    items={breadcrumbs}
                    onClick={(element) => {
                      if (element.detail.item.text !== "Home") element.preventDefault();
                      console.log(`Breadcrumb clicked ${JSON.stringify(element)}`);
                      switch (element.detail.item.text) {
                        case "New RFx":
                          setRadioActive(true);
                          setBasicInfoActive(false);
                          setCustomerInfoActive(false);
                          break;
                        case "Intake Type":
                          setRadioActive(true);
                          setBasicInfoActive(false);
                          setCustomerInfoActive(false);
                          setBreadcrumbs([
                            { text: 'Home', href: '/' },
                            { text: 'New RFx', href: '/NewRFX' },
                            { text: 'Intake Type', href: '/#' }
                          ])
                          break;
                        case "Basic Information":
                          setRadioActive(false);
                          setBasicInfoActive(true);
                          setCustomerInfoActive(false);
                          setBreadcrumbs([
                            { text: 'Home', href: '/' },
                            { text: 'New RFx', href: '/NewRFX' },
                            { text: 'Intake Type', href: '/#' },
                            { text: 'Basic Information', href: '/#' }
                          ])
                          break;
                        case "Customer Information":
                          setRadioActive(false);
                          setBasicInfoActive(false);
                          setCustomerInfoActive(true);
                          setChecklistActive(false);
                          setBreadcrumbs([
                            { text: 'Home', href: '/' },
                            { text: 'New RFx', href: '/NewRFX' },
                            { text: 'Intake Type', href: '/#' },
                            { text: 'Basic Information', href: '/#' },
                            { text: 'Customer Information', href: '/#' }
                          ])
                          break;
                        default:
                          break;
                      }
                    }}
                  />
                </Header>
              }
            >
              <form onSubmit={e => e.preventDefault()}>
                <Form
                  actions={
                    <SpaceBetween direction="horizontal" size="s">
                      <Button formAction="none" variant="link" href='#' onClick={() => {
                        setRadioActive(false);
                        setBasicInfoActive(false);
                        setCustomerInfoActive(false);
                        setChecklistActive(false);
                        setReviewActive(false);
                      }}>
                        Home
                      </Button>
                      <Button href='/MyItems' variant='link'>My Items</Button>
                    </SpaceBetween>
                  }
                >
                  <SpaceBetween size="s" direction='vertical'>
                    <ColumnLayout columns={3} variant='text-grid'>
                      <div>
                        <Box variant="awsui-key-label">Status</Box>
                        <Icon name='status-positive' size='small' variant='success' />
                      </div>
                      <div>
                        <Box variant='awsui-key-label'>Message</Box>
                        Intake successfully created
                      </div>
                      <div>
                        <Box variant='awsui-key-label'>Intake ID</Box>
                        {newIntakeId.id}
                      </div>

                    </ColumnLayout>

                  </SpaceBetween>
                </Form>
              </form>
            </Container>
          )}
        </ContentLayout>
      </I18nProvider>
    </>
  );

  return (
    content
  )

}