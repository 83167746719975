import { Container, Header ,Box, SpaceBetween, ColumnLayout, StatusIndicator, ExpandableSection, Slider, FormField, Textarea } from '@cloudscape-design/components';
import { useEffect, useState } from 'react';

export default function ItemDetailSolutionsChecklist(props: any) {

  const data = props.data;
  const sourceData = props.sourceData;
  //Solutions Fit Items

    //Business Landscape
    const [tap, setTAP] = useState('No');
    const [operationalAreas, setOperationalAreas] = useState('No');
    const [multipleCountries, setMultipleCountries] = useState('No');
    const [redFlagCountries, setRedFlagCountries] = useState('No');
    const [matchingLanguageSet, setMatchingLanguageSet] = useState('No');
    const [nativeChannelsSupport, setNativeChannelsSupport] = useState('No');
    const [drOnly, setDROnly] = useState('No');
    const [crmSolution, setCRMSolution] = useState('');

    //Existing Tech Landscape

    const [existingSolution, setExistingSolution] = useState('');
    const [existingCloudOrPremise, setExistingCloudOrPromise] = useState('');
    const [percentageOutboundDialer, setPercentageOutboundDialer] = useState(20);
    const [e2eSolutionAware, sete2eSolutionAware] = useState('No');
    const [deliveryModel, setDeliveryModel] = useState('No');
    const [tenderOtherVendor, setTenderOtherVendor] = useState('No');

    //Solutions Landscape
    const [cloudCustomer, setCloudCustomer] = useState('No');
    const [byoc, setBYOC] = useState('No');
    const [pbxRequirements, setPBXRequirements] = useState('No');
    const [backOfficeIntegration, setBackOfficeIntegration] = useState('No');
    const [followTheSun, setFollowTheSun] = useState('No');
    const [requirementNature, setRequirementNature] = useState('');

    //Next Steps
    const [nextSteps, setNextSteps] = useState('');
    const [setExpectations, setSetExpectations] = useState('');
    const [solutionConsultant, setSolutionConsultant] = useState('');


  useEffect(() => {
    if (sourceData[0]) {
      setOperationalAreas(sourceData[0].operationalAreas);
      setMultipleCountries(sourceData[0].multipleCountries);
      setRedFlagCountries(sourceData[0].redFlagCountries);
      setMatchingLanguageSet(sourceData[0].matchingLanguageSet);
      setNativeChannelsSupport(sourceData[0].nativeChannelsSupport);
      setDROnly(sourceData[0].drOnly);
      setCRMSolution(sourceData[0].crmSolution);
      setExistingCloudOrPromise(sourceData[0].existingCloudOrPremise);
      setExistingSolution(sourceData[0].existingSolution);
      setPercentageOutboundDialer(sourceData[0].percentageOutboundDialer);
      sete2eSolutionAware(sourceData[0].e2eSolutionAware);
      setDeliveryModel(sourceData[0].deliveryModel);
      setTenderOtherVendor(sourceData[0].tenderOtherVendor);
      setCloudCustomer(sourceData[0].cloudCustomer);
      setBYOC(sourceData[0].byoc);
      setPBXRequirements(sourceData[0].pbxRequirements);
      setBackOfficeIntegration(sourceData[0].backOfficeIntegration);
      setFollowTheSun(sourceData[0].followTheSun);
      setRequirementNature(sourceData[0].requirementNature);
      setNextSteps(sourceData[0].nextSteps);
      setSetExpectations(sourceData[0].setExpectations);
      setSolutionConsultant(sourceData[0].solutionConsultant);
    }

  })

  const content =
    (
      <ExpandableSection headerText='Solutions Fit Checklist' >
       <ColumnLayout columns={4} variant="text-grid">
        <SpaceBetween size='l'>
        <div>
            <Box variant='awsui-key-label'>TAP</Box>
            <StatusIndicator type={tap == 'Yes' ? 'success' : 'warning'}>{tap}</StatusIndicator>
          </div>
          <div>
            <Box variant='awsui-key-label'>Other operational areas</Box>
            <StatusIndicator type='success'>{operationalAreas}</StatusIndicator>
          </div>
          <div>
            <Box variant='awsui-key-label'>Multiple Countries</Box>
            <StatusIndicator type='success'>{multipleCountries}</StatusIndicator>
          </div>
          <div>
            <Box variant='awsui-key-label'>Red-Flag Countries</Box>
            <StatusIndicator type={redFlagCountries == 'Yes' ? 'warning' : 'success'}>{redFlagCountries}</StatusIndicator>
          </div>
          <div>
            <Box variant='awsui-key-label'>Language Set matching</Box>
            <StatusIndicator type={matchingLanguageSet == 'Yes' ? 'success' : 'warning'}>{matchingLanguageSet}</StatusIndicator>
          </div>
          <div>
            <Box variant='awsui-key-label'>Cloud Customer</Box>
            <StatusIndicator type={cloudCustomer == 'Yes' ? 'success' : 'warning'}>{cloudCustomer}</StatusIndicator>
          </div>
        </SpaceBetween>
        <SpaceBetween size='l'>
          <div>
            <Box variant='awsui-key-label'>Channels natively supported</Box>
            <StatusIndicator type={nativeChannelsSupport == 'Yes' ? 'success' : 'warning'}>{nativeChannelsSupport}</StatusIndicator>
          </div>
          <div>
            <Box variant='awsui-key-label'>DR-only solution</Box>
            <StatusIndicator type={drOnly == 'Yes' ? 'warning' : 'success'}>{drOnly}</StatusIndicator>
          </div>
          <div>
            <Box variant='awsui-key-label'>Outbound Call percentage</Box>
            <StatusIndicator type={percentageOutboundDialer < 50 ? 'success' : 'warning'}>{percentageOutboundDialer}%</StatusIndicator>
          </div>
          <div>
            <Box variant='awsui-key-label'>Solution Components</Box>
            <StatusIndicator type={e2eSolutionAware == 'Yes' ? 'success' : 'warning'}>{e2eSolutionAware}</StatusIndicator>
          </div>
          <div>
            <Box variant='awsui-key-label'>Delivery Model</Box>
            <StatusIndicator type='success'>{deliveryModel}</StatusIndicator>
          </div>
        </SpaceBetween>
        <SpaceBetween size='l'>
        <div>
            <Box variant='awsui-key-label'>Tender bias</Box>
            <StatusIndicator type={tenderOtherVendor == 'Yes' ? 'warning' : 'success'}>{tenderOtherVendor}</StatusIndicator>
          </div>
          <div>
            <Box variant='awsui-key-label'>Cloud Buy-In</Box>
            <StatusIndicator type={cloudCustomer == 'Yes' ? 'success' : 'warning'}>{cloudCustomer}</StatusIndicator>
          </div>
          <div>
            <Box variant='awsui-key-label'>BYOC</Box>
            <StatusIndicator type={byoc == 'Yes' ? 'warning' : 'success'}>{byoc}</StatusIndicator>
          </div>
          <div>
            <Box variant='awsui-key-label'>Follow-the-Sun</Box>
            <StatusIndicator type={followTheSun == 'Yes' ? 'warning' : 'success'}>{followTheSun}</StatusIndicator>
          </div>
          <div>
            <Box variant='awsui-key-label'>Backoffice integrations</Box>
            <StatusIndicator type={backOfficeIntegration == 'Yes' ? 'warning' : 'success'}>{backOfficeIntegration}</StatusIndicator>
          </div>
        </SpaceBetween>
        <SpaceBetween size='l'>
        <div>
            <Box variant='awsui-key-label'>CRM Solution</Box>
            <Textarea
            value={crmSolution}
            disabled
            rows={2} />
          </div>
          <div>
            <Box variant='awsui-key-label'>Existing Solution</Box>
            <Textarea
            value={existingSolution}
            disabled
            rows={2} />
          </div>
          <div>
            <Box variant='awsui-key-label'>Cloud or On-Premis</Box>
            <Textarea
            value={existingCloudOrPremise}
            disabled
            rows={2} />
          </div>
          <div>
            <Box variant='awsui-key-label'>Nature of Requirements</Box>
            <Textarea
            value={requirementNature}
            disabled
            rows={2} />
          </div>
          <div>
            <Box variant='awsui-key-label'>Next steps</Box>
            <Textarea
            value={nextSteps}
            disabled
            rows={2} />
          </div>
          <div>
            <Box variant='awsui-key-label'>Customer expectations</Box>
            <Textarea
            value={setExpectations}
            disabled
            rows={2} />
          </div>
          <div>
            <Box variant='awsui-key-label'>Solutions Consultant</Box>
            <Textarea
            value={solutionConsultant}
            disabled
            rows={2} />
          </div>
        </SpaceBetween>
          
          
          
       </ColumnLayout>
      </ExpandableSection>
    )
  return content
}
