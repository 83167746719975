import React, { useEffect, useState } from 'react';
import { Outlet } from "react-router-dom";
import packageJson from "../../package.json";
import {
  AppLayout,
  ContentLayout,
  Flashbar,
  Header,
  HelpPanel,
  Link,
  SideNavigation,
  TopNavigation
} from '@cloudscape-design/components';
import { I18nProvider } from '@cloudscape-design/components/i18n';
import messages from '@cloudscape-design/components/i18n/messages/all.en';
import { Amplify } from 'aws-amplify';
import { getCurrentUser, signInWithRedirect, fetchUserAttributes } from 'aws-amplify/auth';
import { UserContext } from '../context/userContext';
import { UserInfoContextType, UserInfo } from '../@types/user';
import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import Changelog from './components/Changelog';


export default function Root() {
  type Props = {
    user: UserInfo
    updateUser: (userObject: UserInfo) => void
  }
  const { user, updateUser } = React.useContext(UserContext) as UserInfoContextType;
  /*const [user, setUser] = React.useState<UserInfo>(
    {
      id: 1,
      username: 'Not logged in',
      loggedIn: false,
    }
  ); */
  const [loginStatus, setLoginStatus] = useState(false);
  const [usernameRecognized, setUsername] = useState("Not logged in");
  const [userEmail, setUserEmail] = useState("Not logged in");

  const handleUpdateUser = () => {
    const newUser: UserInfo = {
      id: 2,
      username: 'new username',
      usermail: 'not@logged.in',
      loggedIn: true,
    };
    updateUser(newUser);
  };

  useEffect(() => {
    async function getUserEmail() {
      if (user.loggedIn === true) {
        try {
          const userAttributes = await fetchUserAttributes();
          if (userAttributes.email) {
            setUserEmail(userAttributes.email);
            const obj = user;
            user.usermail = userAttributes.email;
            updateUser(obj);
          }
        } catch (error) {
          console.log(`Error getting user email ${error} `);
        }

      }
    }

    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolClientId: "4ujeo5mk5g1tln9lj47f0labca",
          userPoolId: "eu-west-3_Y4ocQjBf8",
          loginWith: {
            oauth: {
              domain: "rfxtooling-qualilist.auth.eu-west-3.amazoncognito.com",
              redirectSignIn: ["https://d3gqyvdo7u8aco.cloudfront.net", "http://localhost:3000", "https://rfx-qualification.anycompany-connect.eu"],
              redirectSignOut: ["https://d3gqyvdo7u8aco.cloudfront.net", "http://localhost:3000", "https://rfx-qualification.anycompany-connect.eu"],
              responseType: "code",
              scopes: ["email", "openid", "aws.cognito.signin.user.admin"]
            },
            email: true,
            username: true
          }
        }
      }
    })
    const checkIfUserLoggedIn = async () => {
      try {
        console.log(packageJson.version); // "1.0.0"
        console.log(`Checking if user is already logged in`);
        const { username, userId } = await getCurrentUser();

        console.log(`User ID ${userId} logged in already for username ${username} .`);

        setUsername(username.replace(/federateoidc/g, '').replace(/oidcprod_/g, ''));
        getUserEmail();
        updateUser({ id: 1, username: username.replace(/federateoidc_/g, '').replace(/oidcprod_/g, ''), usermail: userEmail, loggedIn: true });

        return true;
      } catch {
        console.log(`User is not logged in. Redirecting to login page`);
        signInWithRedirect({
          provider: {
            custom: "OIDCProd"
          }
        });
        return false;
      }
    };
    checkIfUserLoggedIn().then((data) => {
      setLoginStatus(data);
      getUserEmail();

      // * Cloudwatch RUM

      try {
        const config: AwsRumConfig = {
          sessionSampleRate: 1,
          identityPoolId: "eu-west-3:3de3d977-2179-4e1d-a446-5238cafc12d0",
          endpoint: "https://dataplane.rum.eu-west-3.amazonaws.com",
          telemetries: ["errors", "performance", "http"],
          allowCookies: true,
          enableXRay: false
        };

        const APPLICATION_ID: string = '1ebcbd24-63a3-437f-9874-73ab332b62b0';
        const APPLICATION_VERSION: string = '1.0.0';
        const APPLICATION_REGION: string = 'eu-west-3';

        const awsRum: AwsRum = new AwsRum(
          APPLICATION_ID,
          APPLICATION_VERSION,
          APPLICATION_REGION,
          config
        );
      } catch (error) {
        // Ignore errors thrown during CloudWatch RUM web client initialization
        console.log(`Error initializing CloudWatch RUM ${error}`);
      }

    });
  }, [loginStatus, usernameRecognized]);
  const LOCALE = 'en';

  /*
    Sample flashbar item: {
        type: 'info',
        dismissible: true,
        content: 'This is an info flash message.',
        id: 'message_1',
        onDismiss: () => {
          console.log('Flash message dismissed');
          setFlashBarItems([])
        },
      }
    */
  const [flashBarItems, setFlashBarItems] = useState<any | null>([]);
  const [naviOpen, setNaviOpen] = useState(true);
  const [breadcrumbs, setBreadcrumbs] = useState([
    { text: 'Home', href: '/' }
  ]);
  const breadcrumbsContext = React.createContext([
    { text: 'Home', href: '/' }
  ]);

  const switchNavi = () => {
    naviOpen ? setNaviOpen(false) : setNaviOpen(true);
  }

  const FlashbarInfo = (<Flashbar items={flashBarItems} />);
  return (
    <>
      <I18nProvider locale={LOCALE} messages={[messages]}>
        <TopNavigation
          identity={{
            href: '#',
            title: `RFx Qualification Tool`,
            logo: {
              src: 'connect_logo.png',
              alt: 'RFx Qualification Tool'
            }
          }}
          utilities={[
            {
              type: "menu-dropdown",
              text: `Help - v${packageJson.version}`,
              description: "Create a Bug report or feature request",
              iconName: "ticket",
              items: [
                { id: "Changelog", text: "View Changelog", },
                { id: "bug-ticket", text: "Bug Report", href: "https://t.corp.amazon.com/create/templates/7269bf8a-99ef-4214-b6ae-70964da6c036", external: true, externalIconAriaLabel: " (opens in new tab)" },
                { id: "feature-ticket", text: "Feature Request", href: "https://t.corp.amazon.com/create/templates/19bb68ee-706f-49a5-902d-48726bf83237", external: true, externalIconAriaLabel: " (opens in new tab)" },
                { id: "slack-channel", text: "Slack Channel", href: "https://amazon.enterprise.slack.com/archives/C07KWLT5MCY", external: true, externalIconAriaLabel: " (opens in new tab)" }
              ],
              onItemClick: (detail) => {
                console.log(detail.detail.id);
                if(detail.detail.id === 'Changelog') {
                  updateUser({ showChangelog: true, id: user.id, username: user.username, usermail: user.usermail, loggedIn: user.loggedIn});
                }
               }
            },
            {
              type: "menu-dropdown",
              text: user.username,
              description: user.usermail + " This is a placeholder menu only.",
              iconName: "user-profile",
              items: [
                { id: "profile", text: "Profile" },
                { id: "preferences", text: "Preferences" },
                { id: "security", text: "Security" },
                {
                  id: "support-group",
                  text: "Support",
                  items: [
                    {
                      id: "documentation",
                      text: "Documentation",
                      href: "#",
                      external: true,
                      externalIconAriaLabel:
                        " (opens in new tab)"
                    },
                    { id: "support", text: "Support" },
                    {
                      id: "feedback",
                      text: "Feedback",
                      href: "#",
                      external: true,
                      externalIconAriaLabel:
                        " (opens in new tab)"
                    }
                  ]
                },
                { id: "signout", text: "Sign out" }
              ]
            }
          ]}
        />
        <AppLayout
          navigationOpen={naviOpen}
          onNavigationChange={switchNavi}
          navigation={
            <SideNavigation
              header={{
                href: '#',
                text: `EMEA Project Orpheus`,
                logo: { alt: "logo", src: "connect_logo.png" }
              }}
              items={[
                { type: 'link', text: `Home`, href: `${window.location.origin}/Home` },
                { type: 'link', text: `My Items`, href: `${window.location.origin}/MyItems` },
                { type: 'link', text: `New RFX`, href: `${window.location.origin}/NewRFX` },
                { type: 'link', text: `All Items`, href: `${window.location.origin}/AllItems` }
              ]}
            />
          }
          notifications={
            FlashbarInfo
          }
          toolsOpen={false}
          tools={<HelpPanel header={<h2>Overview</h2>}>Help content</HelpPanel>}
          content={
            <ContentLayout

              headerBackgroundStyle='white'
            >
              <Outlet />
            </ContentLayout>
          }

        />
        {user.showChangelog === true && (
          <Changelog />
        )}
        
      </I18nProvider>
    </>
  )
}