import React, { Component, useState, useEffect } from 'react';
import Markdown from 'react-markdown';
import Modal from "@cloudscape-design/components/modal";
import CHANGELOG from './CHANGELOG.md';
import { UserContext } from '../../context/userContext';
import { UserInfoContextType, UserInfo } from '../../@types/user';
import { Container, TextContent } from '@cloudscape-design/components';

export default function Changelog() {
    const [visible, setVisible] = React.useState(false);
    const [postMarkdown, setPostMarkdown] = useState('');
    const { user, updateUser } = React.useContext(UserContext) as UserInfoContextType;

    useEffect(() => {
        fetch(CHANGELOG)
            .then((response) => response.text())
            .then((text) => {
                // Logs a string of Markdown content.
                // Now you could use e.g. <rexxars/react-markdown> to render it.
                // console.log(text);
                setPostMarkdown(text);
            });
    }, []);

    const content =
        (
            <Modal

                onDismiss={() => updateUser({showChangelog: false, id: user.id, username: user.username, loggedIn: user.loggedIn, usermail: user.usermail})}
                visible={user.showChangelog!}
                size='max'>
                    <Container>
                        <TextContent>
                <Markdown>
                    {postMarkdown}
                </Markdown>
                </TextContent>
                </Container>
            </Modal>
        )
    return content
}