import { Container, ButtonDropdown, Header ,Box, SpaceBetween, ColumnLayout, StatusIndicator, ExpandableSection, Slider, FormField, Textarea } from '@cloudscape-design/components';
import { useEffect, useState } from 'react';

export default function EditItemDetailSolutionsChecklist(props: any) {

  const data = props.data;
  const sourceData = props.sourceData;
  const updateParent = props.sendDataToParent;
  
  //Solutions Fit Items

    //Business Landscape
    const [tap, setTAP] = useState('No');
    const [operationalAreas, setOperationalAreas] = useState('No');
    const [multipleCountries, setMultipleCountries] = useState('No');
    const [redFlagCountries, setRedFlagCountries] = useState('No');
    const [matchingLanguageSet, setMatchingLanguageSet] = useState('No');
    const [nativeChannelsSupport, setNativeChannelsSupport] = useState('No');
    const [drOnly, setDROnly] = useState('No');
    const [crmSolution, setCRMSolution] = useState('');

    //Existing Tech Landscape

    const [existingSolution, setExistingSolution] = useState('');
    const [existingCloudOrPremise, setExistingCloudOrPromise] = useState('');
    const [percentageOutboundDialer, setPercentageOutboundDialer] = useState(20);
    const [e2eSolutionAware, sete2eSolutionAware] = useState('No');
    const [deliveryModel, setDeliveryModel] = useState('No');
    const [tenderOtherVendor, setTenderOtherVendor] = useState('No');

    //Solutions Landscape
    const [cloudCustomer, setCloudCustomer] = useState('No');
    const [byoc, setBYOC] = useState('No');
    const [pbxRequirements, setPBXRequirements] = useState('No');
    const [backOfficeIntegration, setBackOfficeIntegration] = useState('No');
    const [followTheSun, setFollowTheSun] = useState('No');
    const [requirementNature, setRequirementNature] = useState('');

    //Next Steps
    const [nextSteps, setNextSteps] = useState('');
    const [setExpectations, setSetExpectations] = useState('');
    const [solutionConsultant, setSolutionConsultant] = useState('');


  useEffect(() => {
    if (sourceData[0]) {
      setOperationalAreas(sourceData[0].operationalAreas);
      setMultipleCountries(sourceData[0].multipleCountries);
      setRedFlagCountries(sourceData[0].redFlagCountries);
      setMatchingLanguageSet(sourceData[0].matchingLanguageSet);
      setNativeChannelsSupport(sourceData[0].nativeChannelsSupport);
      setDROnly(sourceData[0].drOnly);
      setCRMSolution(sourceData[0].crmSolution);
      setExistingCloudOrPromise(sourceData[0].existingCloudOrPremise);
      setExistingSolution(sourceData[0].existingSolution);
      setPercentageOutboundDialer(sourceData[0].percentageOutboundDialer);
      sete2eSolutionAware(sourceData[0].e2eSolutionAware);
      setDeliveryModel(sourceData[0].deliveryModel);
      setTenderOtherVendor(sourceData[0].tenderOtherVendor);
      setCloudCustomer(sourceData[0].cloudCustomer);
      setBYOC(sourceData[0].byoc);
      setPBXRequirements(sourceData[0].pbxRequirements);
      setBackOfficeIntegration(sourceData[0].backOfficeIntegration);
      setFollowTheSun(sourceData[0].followTheSun);
      setRequirementNature(sourceData[0].requirementNature);
      setNextSteps(sourceData[0].nextSteps);
      setSetExpectations(sourceData[0].setExpectations);
      setSolutionConsultant(sourceData[0].solutionConsultant);
    }

  }, [data, sourceData])

  useEffect(() => {
    updateParent({
      operationalAreas: operationalAreas,
      multipleCountries: multipleCountries,
      redFlagCountries: redFlagCountries,
      matchingLanguageSet: matchingLanguageSet,
      nativeChannelsSupport: nativeChannelsSupport,
      drOnly: drOnly,
      crmSolution: crmSolution,
      existingCloudOrPremise: existingCloudOrPremise,
      existingSolution: existingSolution,
      percentageOutboundDialer: percentageOutboundDialer,
      e2eSolutionAware: e2eSolutionAware,
      deliveryModel: deliveryModel,
      tenderOtherVendor: tenderOtherVendor,
      cloudCustomer: cloudCustomer,
      byoc: byoc,
      pbxRequirements: pbxRequirements,
      backOfficeIntegration: backOfficeIntegration,
      followTheSun: followTheSun,
      requirementNature: requirementNature,
      nextSteps: nextSteps,
      setExpectations: setExpectations,
      solutionConsultant: solutionConsultant
    });
  }, [tap, operationalAreas, multipleCountries, redFlagCountries, matchingLanguageSet, nativeChannelsSupport, drOnly, crmSolution, existingCloudOrPremise, existingSolution, percentageOutboundDialer, e2eSolutionAware, deliveryModel, tenderOtherVendor, cloudCustomer, byoc, pbxRequirements, backOfficeIntegration, followTheSun, requirementNature, nextSteps, setExpectations, solutionConsultant])

  const content =
    (
      <ExpandableSection headerText='Solutions Fit Checklist' >
       <ColumnLayout columns={4} variant="text-grid">
        <SpaceBetween size='l'>
        <div>
            <Box variant='awsui-key-label'>TAP</Box>
            <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setTAP(event.detail.id);
                                            }}
                                        >{tap} </ButtonDropdown>
          </div>
          <div>
            <Box variant='awsui-key-label'>Other operational areas</Box>
            <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setOperationalAreas(event.detail.id);
                                            }}
                                        >{operationalAreas} </ButtonDropdown>
          </div>
          <div>
            <Box variant='awsui-key-label'>Multiple Countries</Box>
            <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setMultipleCountries(event.detail.id);
                                            }}
                                        >{multipleCountries} </ButtonDropdown>
          </div>
          <div>
            <Box variant='awsui-key-label'>Red-Flag Countries</Box>
            <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setRedFlagCountries(event.detail.id);
                                            }}
                                        >{redFlagCountries} </ButtonDropdown>
          </div>
          <div>
            <Box variant='awsui-key-label'>Language Set matching</Box>
            <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setMatchingLanguageSet(event.detail.id);
                                            }}
                                        >{matchingLanguageSet} </ButtonDropdown>
          </div>
          <div>
            <Box variant='awsui-key-label'>Cloud Customer</Box>
            <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setCloudCustomer(event.detail.id);
                                            }}
                                        >{cloudCustomer} </ButtonDropdown>
          </div>
        </SpaceBetween>
        <SpaceBetween size='l'>
          <div>
            <Box variant='awsui-key-label'>Channels natively supported</Box>
            <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setNativeChannelsSupport(event.detail.id);
                                            }}
                                        >{nativeChannelsSupport} </ButtonDropdown>
          </div>
          <div>
            <Box variant='awsui-key-label'>DR-only solution</Box>
            <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setDROnly(event.detail.id);
                                            }}
                                        >{drOnly} </ButtonDropdown>
          </div>
          <div>
            <Box variant='awsui-key-label'>Outbound Call percentage</Box>
            <Slider
                                            onChange={({ detail }) => setPercentageOutboundDialer(detail.value)}
                                            value={percentageOutboundDialer}
                                            valueFormatter={value => value + "%"}
                                            max={100}
                                            min={0}
                                            referenceValues={[25, 50, 75]}
                                        />
          </div>
          <div>
            <Box variant='awsui-key-label'>Solution Components</Box>
            <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                sete2eSolutionAware(event.detail.id);
                                            }}
                                        >{e2eSolutionAware} </ButtonDropdown>
          </div>
          <div>
            <Box variant='awsui-key-label'>Delivery Model</Box>
            <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setDeliveryModel(event.detail.id);
                                            }}
                                        >{deliveryModel} </ButtonDropdown>
          </div>
        </SpaceBetween>
        <SpaceBetween size='l'>
        <div>
            <Box variant='awsui-key-label'>Tender bias</Box>
            <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setTenderOtherVendor(event.detail.id);
                                            }}
                                        >{tenderOtherVendor} </ButtonDropdown>
          </div>
          <div>
            <Box variant='awsui-key-label'>Cloud Buy-In</Box>
            <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setCloudCustomer(event.detail.id);
                                            }}
                                        >{cloudCustomer} </ButtonDropdown>
          </div>
          <div>
            <Box variant='awsui-key-label'>BYOC</Box>
            <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setBYOC(event.detail.id);
                                            }}
                                        >{byoc} </ButtonDropdown>
          </div>
          <div>
            <Box variant='awsui-key-label'>Follow-the-Sun</Box>
            <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setFollowTheSun(event.detail.id);
                                            }}
                                        >{followTheSun} </ButtonDropdown>
          </div>
          <div>
            <Box variant='awsui-key-label'>Backoffice integrations</Box>
            <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setBackOfficeIntegration(event.detail.id);
                                            }}
                                        >{backOfficeIntegration} </ButtonDropdown>
          </div>
        </SpaceBetween>
        <SpaceBetween size='l'>
        <div>
            <Box variant='awsui-key-label'>CRM Solution</Box>
            <Textarea
                                            onChange={({ detail }) => setCRMSolution(detail.value)}
                                            value={crmSolution}
                                            placeholder="Enter CRM Solution if available."
                                        />
          </div>
          <div>
            <Box variant='awsui-key-label'>Existing Solution</Box>
            <Textarea
                                            onChange={({ detail }) => setExistingSolution(detail.value)}
                                            value={existingSolution}
                                            placeholder="Enter existing Solution if available."
                                        />
          </div>
          <div>
            <Box variant='awsui-key-label'>Cloud or On-Premis</Box>
            <Textarea
                                            onChange={({ detail }) => setExistingCloudOrPromise(detail.value)}
                                            value={existingCloudOrPremise}
                                            placeholder="Please describe."
                                        />
          </div>
          <div>
            <Box variant='awsui-key-label'>Nature of Requirements</Box>
            <Textarea
                                            onChange={({ detail }) => setRequirementNature(detail.value)}
                                            value={requirementNature}
                                            placeholder="Describe the customer requirements."
                                        />
          </div>
          <div>
            <Box variant='awsui-key-label'>Next steps</Box>
            <Textarea
                                            onChange={({ detail }) => setNextSteps(detail.value)}
                                            value={nextSteps}
                                            placeholder="Describe the next steps."
                                        />
          </div>
          <div>
            <Box variant='awsui-key-label'>Customer expectations</Box>
            <Textarea
                                            onChange={({ detail }) => setSetExpectations(detail.value)}
                                            value={setExpectations}
                                            placeholder="Describe the customer expectations."
                                        />
          </div>
          <div>
            <Box variant='awsui-key-label'>Solutions Consultant</Box>
            <Textarea
                                            onChange={({ detail }) => setSolutionConsultant(detail.value)}
                                            value={solutionConsultant}
                                            placeholder="Describe the customer requirements."
                                        />
          </div>
        </SpaceBetween>
          
          
          
       </ColumnLayout>
      </ExpandableSection>
    )
  return content
}
